import { isEmpty, get } from 'lodash-es';
import { useCartStore, soloUserCookie } from '~/store/cart';
import { useCustomerStore } from '~/store/customer';
import { getCookie, setCookie, deleteCookie } from '~/util/storage';
import { errorCatchCB, truncateApiError, sentryScopedException } from '~/util/error';
import { checkDebug, cartNotExistCheck, mapProductAddToCart, cartItemsModifiedMap } from '~/util/cart';
import { useGeodataStore } from '~/store/geodata';
import { callController } from '~/util/bcDataFetching';
import { useTestStore } from '~/store/test';
import { useGiftsStore } from '~/store/gifts';
import { CheckoutType, AddToCartItem } from '~/types/bigcommerce';
import {
  GtmEventType,
  mapBcCartItemsToGtmEvent,
  baseEcommerceEventStructure,
  mapCtsCartItemsToGtmCartItems,
} from '~/util/analytics';

const cartErrors = {
  cartNotSet: 'Cart not set',
  noItems: 'No cart items to add',
  cartNotFound: 'Cart not found',
};

const cartDaysTtl = 30;

class CartException {
  name: string;
  message: string;
  level: string;
  constructor(message: string, level = 'log') {
    this.name = 'CartException';
    this.message = message;
    this.level = level;
  }
}

// If the below is the wanted pattern for this set of composables, replace the contents
// of this file with 'experimentalCartCalls.ts' and finish typing and abstracting the methods in the composable.

// type CartCallMethods =
//   | 'cartBCValidate';

// export function useCartCallsExperimental<T extends CartCallMethods>(cartCallMethod: T) {
//   const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();
//   const { regionCode } = regionLocaleProperties.value;
//   const _headers = {
//     [regionHeaderName]: regionCode,
//   };

//   const cartStore = useCartStore();

//   class Actions {
//     cartBCValidate = async () => {
//       let cartResetter = false;
//       try {
//         const jwt = getCookie(soloUserCookie);
//         if (!jwt) {
//           if (cartStore.cartId) throw new CartException('Could not validate cart: cart cookie not set.', 'info');
//           return;
//         }
//         const body = JSON.stringify({
//           jwt,
//         });
//         const req = await useFetchApiLayer(
//           '/cartValidateBasic',
//           {
//             method: 'POST',
//             body,
//           },
//           true
//         );
//         const res = req._data;
//         if (req?.status >= 400 && typeof res === 'string') {
//           // TODO: whitelist errors we want to reset the cart
//           cartResetter = true;
//           throw new CartException(truncateApiError(res), 'info');
//         } else if (res?.status === 404) {
//           cartResetter = true;
//           throw new CartException(cartErrors.cartNotFound);
//         }
//         const redirectUrls = res?.data?.redirect_urls;
//         if (redirectUrls) cartStore.SET_REDIRECTURLS(redirectUrls);
//       } catch (error: any) {
//         this.handleCartError({ error }); // for when the whole class is written
//          // right now, if used, will throw an error
//         if (cartResetter) cartStore.resetCart();
//       }
//     };
//   }

//   const actionsInst = new Actions();
//   return actionsInst[cartCallMethod];
// }

// export async function useCartBCValidateExperimental() {
//   // if a method has args, define a type for it and use the type in the method
//   // and in the abstracted composable. 'cartBCValidate' takes no arguments,
//   // so it is not necessary in this composable.
//   await useCartCallsExperimental<'cartBCValidate'>('cartBCValidate')();
// }

// export async function useAddToCartExperimental(data: AddToCartArgs) {
//   // not implemented, but this is how it would be used
//   await useCartCalls<'addToCart'>('addToCart')(data);
// }

/*
  There may be a lot of repetition in setting up the cart calls, so implementing this pattern
  may give us a way to use common setups to avoid that, while using specific setups in the
  methods defined within the 'Actions' class.
  The usage is outlined in 'useCartBCValidateExperimental. When the class has all of its methods,
  it will become necessary to use the generic setter to get the proper return type that
  has the correct call signatures due to a lot of them sharing similar/same signatures.
*/

export async function useCartBCValidate() {
  const cartStore = useCartStore();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  const cartId = await useGetCartId();

  let cartResetter = false;
  try {
    const jwt = getCookie(soloUserCookie);
    if (!jwt) {
      if (cartId) throw new CartException('Could not validate cart: cart cookie not set.', 'info');
      return;
    }
    const body = JSON.stringify({
      jwt,
    });
    const req = await useFetchApiLayer(
      '/cartValidateBasic',
      {
        method: 'POST',
        headers,
        body,
      },
      true
    );
    // console.log('req', req);
    const res = req._data;
    if (req?.status >= 400 && typeof res === 'string') {
      // TODO: whitelist errors we want to reset the cart
      cartResetter = true;
      throw new CartException(truncateApiError(res), 'info');
    } else if (res?.status === 404) {
      cartResetter = true;
      throw new CartException(cartErrors.cartNotFound);
    }
    const redirectUrls = res?.data?.redirect_urls;
    if (redirectUrls) cartStore.SET_REDIRECTURLS(redirectUrls);
  } catch (error: any) {
    useHandleCartError({ error });
    if (cartResetter) cartStore.resetCart();
  }
}

export async function useGetCartId() {
  const cartStore = useCartStore();

  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const cartCookie = getCookie(soloUserCookie);
    if (!cartCookie) return Promise.resolve(undefined);

    const req = await useFetchApiLayer(
      `/cartValidate`,
      {
        method: 'POST',
        headers,
        body: cartCookie,
      },
      true
    );
    const res: any = req._data;
    if (req?.status >= 400 && typeof res === 'string') {
      throw new CartException(truncateApiError(res), 'info');
    } else if (req?.status >= 400) {
      throw new CartException(res, 'info');
    }
    const cartCustomerId = get(res, 'payload.customer_id');
    const customerStore = useCustomerStore();
    const customerId = customerStore?.customer?.id;
    const cartId = get(res, 'payload.id');
    if (cartCustomerId !== undefined && cartCustomerId !== 0 && cartCustomerId !== customerId) {
      return undefined;
    }

    return cartId;
  } catch (error: any) {
    useHandleCartError({ error });
    deleteCookie(soloUserCookie, domain);
    await cartStore.deleteCart();
    // await this.deleteCart', { commit });
    return Promise.resolve(undefined);
  }
}

export async function useAddToCart({
  cartItems,
  setLatestAdditions = true,
}: {
  cartItems: AddToCartItem[];
  setLatestAdditions?: boolean;
}) {
  const cartId = await useGetCartId();
  if (cartId) {
    await useAddCartItems({ cartItems, cartId, setLatestAdditions });
  } else {
    await useCreateCart({ cartItems, setLatestAdditions });
  }

  const gtm = useGtm();
  const gtmItems = mapCtsCartItemsToGtmCartItems(cartItems);
  const gtmEvent = baseEcommerceEventStructure({ eventType: GtmEventType.addToCart, items: gtmItems });
  gtm?.trackEvent(gtmEvent);

  const metaPixel = useMetaPixel();
  const contentName = gtmItems?.[0]?.item_name;
  metaPixel?.trackEvent({ event: 'AddToCart', eventItems: gtmItems, contentName });

  const { $bus }: any = useNuxtApp();
  $bus.emit('a2c', cartItems);
}

export async function useGetCart() {
  const cartStore = useCartStore();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const jwt = getCookie(soloUserCookie);
    if (!jwt) {
      if (cartStore.cartId) throw new CartException(cartErrors.cartNotSet, 'debug');
      return;
    }
    const body = JSON.stringify({
      jwt,
    });
    const req = await useFetchApiLayer(
      '/getCart',
      {
        method: 'POST',
        headers,
        body,
      },
      true
    );
    const data: any = req._data;
    if (req?.status >= 400 && typeof data === 'string') {
      throw new CartException(truncateApiError(data), 'info');
    }
    const cartCustomerId = get(data, 'cartData.cart.customer_id');
    const cartJwt = get(data, 'cartData.jwt');
    const customerStore = useCustomerStore();
    const customerId = customerStore?.customer?.id;
    // If existing session for logged in user, but not logged in currently, don't return cart.
    if (cartCustomerId !== undefined && cartCustomerId !== 0 && cartCustomerId !== customerId) {
      return false;
    }
    if (jwt !== cartJwt) {
      const config = useRuntimeConfig();
      const domain = config?.public?.cookieDomain;
      setCookie(soloUserCookie, cartJwt, cartDaysTtl, true, domain);
    }
    await cartStore.cartSetter(data);
  } catch (error: any) {
    useHandleCartError({ error });
    errorCatchCB(error, false, true);
    cartStore.SET_CART(null);
    cartStore.SET_CART_PRODUCTS([]);
    cartStore.SET_REDIRECTURLS(null);
    return Promise.resolve(error);
  } finally {
    cartStore.setGetCartStatus(false);
  }
}

export async function useGetDraftedCart({ cartId }: { cartId: number }) {
  const config = useRuntimeConfig();
  const cartStore = useCartStore();
  const domain = config?.cookieDomain;
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  try {
    cartStore.setGetCartStatus(true);
    const req = await useFetchApiLayer(
      '/getDraftCart',
      {
        method: 'POST',
        headers: {
          'x-crt-shrt-crct': 'true',
          [regionHeaderName]: regionCode,
          [localeHeaderName]: code,
        },
        body: JSON.stringify({ data: { id: cartId } }),
      },
      true
    );
    const data = req._data;
    if (req?.status >= 400 && typeof data === 'string') {
      throw new CartException(truncateApiError(data), 'info');
    }
    const cartCustomerId = get(data, 'cartData.cart.customer_id');
    const customerStore = useCustomerStore();
    const customerId = customerStore?.customer?.id;
    const jwt = data?.cartData?.jwt;
    setCookie(soloUserCookie, jwt, cartDaysTtl, true, domain);
    // If existing session for logged in user, but not logged in currently, don't return cart.
    if (cartCustomerId !== undefined && cartCustomerId !== 0 && cartCustomerId !== customerId) {
      return true;
    }
    await cartStore.cartSetter(data);
  } catch (error: any) {
    useHandleCartError({ error });
  } finally {
    cartStore.setGetCartStatus(false);
  }
}

export async function useCreateCart({
  cartItems,
  setLatestAdditions = true,
}: {
  cartItems: AddToCartItem[];
  setLatestAdditions?: boolean;
}) {
  const cartStore = useCartStore();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code, currencyCode } = regionLocaleProperties.value;
  const { bcChannelId } = useEnvApiSettings();
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };

  cartStore.setCartActionStatus(true);
  cartStore.setGetCartStatus(true);
  let subIntercept;
  try {
    const customerStore = useCustomerStore();
    const geoStore = useGeodataStore();
    const geo = geoStore?.geo;
    const customerId = customerStore?.customer?.id;
    // const customerGroupId = customer?.customer_group_id;
    /* eslint-disable camelcase */
    const { line_items, gift_certificates } = mapProductAddToCart(cartItems);
    if (line_items.length === 0 && gift_certificates.length === 0) throw new CartException(cartErrors.noItems, 'info');
    const config = useRuntimeConfig();
    const cartData = {
      line_items,
      gift_certificates,
      channel_id: bcChannelId,
      ...(customerId && { customer_id: customerId }),
      geo,
      ...(currencyCode && { currency: { code: currencyCode } }),
    };
    /* eslint-enable camelcase */
    const req = await useFetchApiLayer(
      '/createCart',
      {
        method: 'POST',
        headers,
        body: JSON.stringify(cartData),
      },
      true
    );
    const res: any = req._data;
    if (req?.status >= 400) {
      throw new CartException(truncateApiError(res), 'warning');
    }
    const jwt = res?.cartData?.jwt;
    const domain = config?.public?.cookieDomain;
    setCookie(soloUserCookie, jwt, cartDaysTtl, true, domain);
    subIntercept = res?.recentlyAddedSub;
    await cartStore.cartSetter(res);
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'cart' }],
      context: {
        key: 'cart',
        data: {
          action: 'createCart',
          cartItems,
        },
      },
    });
    cartStore.SET_CART_ERROR(error);
    return Promise.resolve(error);
  } finally {
    let cartItemsMod = cartItems;
    if (subIntercept) {
      cartItemsMod = cartItemsModifiedMap(cartItems, subIntercept);
    }
    if (setLatestAdditions) {
      cartStore.setLatestAdditions(cartItemsMod);
    }
    cartStore.setNewItemAdded(true);
    cartStore.setCartActionStatus(false);
    cartStore.setGetCartStatus(false);
  }
}

export async function useAddCartItems({
  cartItems,
  cartId,
  setLatestAdditions = true,
}: {
  cartItems: AddToCartItem[];
  cartId: string;
  setLatestAdditions?: boolean;
}) {
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  const cartStore = useCartStore();
  let noPassThrough = true;
  let subIntercept;
  cartStore.setCartActionStatus(true);
  cartStore.setGetCartStatus(true);
  try {
    /* eslint-disable camelcase */
    const { line_items, gift_certificates } = mapProductAddToCart(cartItems);
    if (line_items.length === 0 && gift_certificates.length === 0) throw new CartException(cartErrors.noItems);
    const cartData = { line_items, gift_certificates };
    /* eslint-enable camelcase */
    const req = await useFetchApiLayer(
      `/addCartItem?cartId=${cartId}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(cartData),
      },
      true
    );
    const res: any = req._data;
    if (req?.status >= 400 && typeof res === 'string') {
      throw new CartException(truncateApiError(res), 'warning');
    }
    if (res?.item?.status && res?.item?.status >= 400) {
      const cartNotExist = cartNotExistCheck(res.item);
      if (cartNotExist) {
        noPassThrough = false;
        const config = useRuntimeConfig();
        const domain = config?.public?.cookieDomain;
        deleteCookie(soloUserCookie, domain);
        await cartStore.deleteCart();
        await useCreateCart({ cartItems });
      } else {
        cartStore.SET_CART_ERROR(res.item?.title);
        throw new CartException(res.item?.title);
      }
    } else {
      subIntercept = res?.recentlyAddedSub;
      await cartStore.cartSetter(res);
    }
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'cart' }],
      context: {
        key: 'cart',
        data: {
          action: 'addCartItems',
          cartItems,
          cartId,
        },
      },
    });
    noPassThrough = true;
    cartStore.SET_CART_ERROR(error);
    return Promise.resolve(error);
  } finally {
    if (noPassThrough) {
      let cartItemsMod = cartItems;
      if (subIntercept) {
        cartItemsMod = cartItemsModifiedMap(cartItems, subIntercept);
      }
      if (setLatestAdditions) {
        cartStore.setLatestAdditions(cartItemsMod);
      }
      cartStore.setNewItemAdded(true);
      cartStore.setCartActionStatus(false);
      cartStore.setGetCartStatus(false);
    }
  }
}

// TODO - update again to handle parent + variant sku
export async function useAddByQueryParams({ sku }: { sku: string }) {
  const cartStore = useCartStore();
  cartStore.setCartActionStatus(true);
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const productData: any = await callController(headers, {
      sku,
    });
    await useAddToCart({ cartItems: [productData] });
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'cart' }],
      context: {
        key: 'cart',
        data: {
          action: 'addByQueryParams',
          sku,
        },
      },
    });
    return Promise.resolve(error);
  } finally {
    cartStore.setCartActionStatus(false);
  }
}

export async function useUpdateCartItem(cartItem: any, isRemove: boolean) {
  const cartStore = useCartStore();
  const testStore = useTestStore();
  cartStore.setCartActionStatus(true);
  const cartId = await useGetCartId();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const product = cartItem?.product;
    const previousQty = cartItem?.previousQty;
    const itemId = product?.itemId;
    const lineItem: any = {
      quantity: cartItem?.quantity,
      product_id: product?.id,
      subData: product?.subData,
      sku: product?.sku,
    };
    if (product?.variant_id) lineItem.variant_id = product.variant_id;

    const cartData = { line_item: lineItem };

    const data = await useFetchApiLayer(`/updateCartItem?cartId=${cartId}&itemId=${itemId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(cartData),
    });
    if (data?.item?.status && data.item.status === 404) {
      cartStore.SET_CART_ERROR(data);
      throw new Error(data);
    }
    await cartStore.cartSetter(data);
    if (testStore?.giftWithPurchaseTest) {
      await useCheckForGwpItemAndRemove();
    }
    const gtm = useGtm();
    gtm?.trackEvent(
      mapBcCartItemsToGtmEvent(isRemove ? GtmEventType.removeFromCart : GtmEventType.addToCart, [
        { ...cartItem?.product, quantityOverride: Math.abs(cartItem.quantity - previousQty) },
      ])
    );
    const { $bus }: any = useNuxtApp();
    $bus.emit('updateCart', lineItem);
    if (!isRemove) {
      const { $bus }: any = useNuxtApp();
      $bus.emit('a2c', [cartItem]);
    }
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'cart' }],
      context: {
        key: 'cart',
        data: {
          action: 'updateCartItem',
          cartItem,
          cartId,
        },
      },
    });
    cartStore.SET_CART_ERROR(error);
    return Promise.resolve(error);
  } finally {
    cartStore.setCartActionStatus(false);
  }
}

export async function useDeleteCartItem({
  cartItem,
  skipCartActionComplete = false,
}: {
  cartItem: any;
  skipCartActionComplete?: boolean;
}) {
  const cartStore = useCartStore();
  const testStore = useTestStore();
  cartStore.setCartActionStatus(true);
  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;
  let cartId;
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const product = cartItem?.product;
    const itemId = product?.itemId || product?.id;
    const variantId = product?.variant_id;
    const subId = product?.subData?.id;
    cartId = await useGetCartId();
    const data = await useFetchApiLayer(
      `/deleteCartItem?cartId=${cartId}&itemId=${itemId}${variantId ? `&variantId=${variantId}` : ''}${
        subId ? `&subId=${subId}` : ''
      }`,
      { method: 'POST', headers }
    );
    if (data?.item?.status === 404) {
      cartStore.SET_CART_ERROR(data);
      throw new Error(data);
    }
    await cartStore.cartSetter(data);
    if (testStore?.giftWithPurchaseTest) {
      await useCheckForGwpItemAndRemove();
    }
    if (isEmpty(data?.item)) {
      deleteCookie(soloUserCookie, domain);
    }
    const gtm = useGtm();
    gtm?.trackEvent(mapBcCartItemsToGtmEvent(GtmEventType.removeFromCart, [product]));
    const { $bus }: any = useNuxtApp();
    $bus.emit('removeItem', product);
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'cart' }],
      context: {
        key: 'cart',
        data: {
          action: 'deleteCartItem',
          cartItem,
          cartId,
        },
      },
    });
    cartStore.SET_CART_ERROR(error);
    deleteCookie(soloUserCookie, domain);
    return Promise.resolve(error);
  } finally {
    if (!skipCartActionComplete) {
      cartStore.setCartActionStatus(false);
    }
  }
}

export async function useCheckForGwpItemAndRemove() {
  const cartStore = useCartStore();
  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const giftState = useGiftsStore();
    const gwpProducts = giftState?.products;
    const cartProducts = cartStore.products;
    const cartId = await useGetCartId();
    let newCart: any;
    for (let i = 0; i < cartProducts?.length; i++) {
      const foundMatch = gwpProducts.find(
        (item: any) => item?.product?.product_offerings?.bc_primary_product?.product?.id === cartProducts[i]?.id
      );
      if (foundMatch && foundMatch?.minimumSpend > cartStore.totalPrice) {
        const product = cartProducts[i];
        const itemId = product?.itemId || product?.id;
        const variantId = product?.variant_id;
        const subId = product?.subData?.id;
        const result = await useFetchApiLayer(
          `/deleteCartItem?cartId=${cartId}&itemId=${itemId}${variantId ? `&variantId=${variantId}` : ''}${
            subId ? `&subId=${subId}` : ''
          }`,
          { method: 'POST', headers }
        );
        newCart = await result;
        if (newCart?.item?.status === 404) {
          cartStore.SET_CART_ERROR(newCart);
          throw new Error(newCart);
        }
      }
    }
    if (newCart) {
      await cartStore.cartSetter(newCart);
    }
    if (typeof newCart !== 'undefined' && isEmpty(newCart?.item)) {
      deleteCookie(soloUserCookie, domain);
    }
  } catch (error: any) {
    useHandleCartError({ error });
    cartStore.SET_CART_ERROR(error);
    deleteCookie(soloUserCookie, domain);
    return Promise.resolve(error);
  }
}

export async function useUpdateCartWithCustomerId() {
  const cartStore = useCartStore();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const customerState = useCustomerStore();
    const customerId = customerState?.customer?.id;
    const cartId = await useGetCartId();
    const body = JSON.stringify({
      customerId,
    });
    const req = await useFetchApiLayer(
      `/updateCartWithCustomerId?cartId=${cartId}&customerId=${customerId}`,
      {
        method: 'POST',
        headers,
        body,
      },
      true
    );
    if (req?.status === 403) return 0;
    if (req?.status === 400) throw new Error('Bad Request');
    const res = req._data;
    const jwt = res?.item?.jwt;
    const config = useRuntimeConfig();
    const domain = config?.public?.cookieDomain;
    setCookie(soloUserCookie, jwt, cartDaysTtl, true, domain);
    await cartStore.cartSetter(res);
    return res;
  } catch (error: any) {
    useHandleCartError({ error });
    cartStore.SET_CART_ERROR(error);
  }
}

export async function useAddCoupons(couponCode: string) {
  const cartStore = useCartStore();
  cartStore.setCartActionStatus(true);
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    if (!couponCode) console.log('Please input a valid coupon code'); // eslint-disable-line no-console
    else {
      const cartId = await useGetCartId();
      const req = await useFetchApiLayer(
        `/addCoupons?cartId=${cartId}`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ couponCode }),
        },
        true
      );

      const res = req._data;
      if (res?.item?.status !== 400 && res?.item?.status === undefined) {
        cartStore.setCouponRes(res?.item);
        await cartStore.cartSetter(res);
      } else {
        // Cart store checks for 400 status to present user with error message
        cartStore.setCouponRes({ status: 400, message: res?.item?.title });
      }
    }
  } catch (error: any) {
    useHandleCartError({ error, context: { key: 'coupon', data: { couponCode } } });
    cartStore.SET_CART_ERROR(error);
    return Promise.resolve(error);
  } finally {
    cartStore.setCartActionStatus(false);
  }
}

export async function useRemoveCoupon(couponCode: string) {
  const cartStore = useCartStore();
  cartStore.setCartActionStatus(true);
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };
  try {
    const cartId = await useGetCartId();
    const req = await useFetchApiLayer(
      `/deleteCoupons?cartId=${cartId}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ couponCode }),
      },
      true
    );
    console.log('useRemoveCoupon');
    console.log(req);
    const res = req._data;
    await cartStore.cartSetter(res);
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'error',
      tags: [{ 'customer-flow': 'coupon' }],
      context: { key: 'coupon', data: { couponCode } },
    });
    cartStore.SET_CART_ERROR(error);
    return Promise.resolve(error);
  } finally {
    cartStore.setCartActionStatus(false);
  }
}

// Cart to Checkout Sentry Logging
export async function useCartCheckout() {
  let checkoutUrl = '';
  let customerId;
  let checkoutType;
  const cartStore = useCartStore();
  const { regionHeaderName, localeHeaderName, regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode, code } = regionLocaleProperties.value;
  const headers = {
    [regionHeaderName]: regionCode,
    [localeHeaderName]: code,
  };

  try {
    const { $bus }: any = useNuxtApp();
    const config = useRuntimeConfig();
    const cartId = await useGetCartId();
    await useCartBCValidate();
    checkoutUrl = cartStore.redirectUrls.checkout_url;
    const customerStore = useCustomerStore();
    const geoStore = useGeodataStore();
    customerId = customerStore?.customer?.id;
    const ip = geoStore?.geo?.ip;
    if (customerId) {
      const body = JSON.stringify({
        ip,
        customerId,
        redirectTo: cartStore.redirectUrls.checkout_url,
      });
      const res = await useFetchApiLayer(`/customerBcLogin?cartId=${cartId}`, {
        method: 'POST',
        headers,
        body,
      });
      const config = useRuntimeConfig();
      const publicRegionEnvs = config?.public?.regionEnvs?.publicRegionSettings as any;
      const regionalConfig: any = publicRegionEnvs[regionCode];
      const { bcBaseStoreUrl } = regionalConfig;
      if (!res || !res.jwt) throw new CartException('Could not login customer for BC checkout redirect', 'info');
      checkoutUrl = `${bcBaseStoreUrl}/login/token/${res.jwt}`;

      if (checkDebug()) {
        /* eslint-disable no-console */
        console.log(cartStore.redirectUrls?.checkout_url);
        console.log(res?.jwt);
        console.log(checkoutUrl);
        /* eslint-enable no-console */
      }
    }
    $bus.emit('startCheckout');
    checkoutType = config?.public?.checkoutType;
    if (checkoutType === CheckoutType.redirected) {
      if (cartStore.hasSubscriptions) {
        const totalTax = cartStore.totalTax;
        const jwt = getCookie(soloUserCookie);
        const req = await useFetchApiLayer(
          '/recharge-checkout',
          {
            method: 'POST',
            headers,
            body: JSON.stringify({ jwt, totalTax }),
          },
          true
        );
        const res = req._data;
        if (req?.status >= 400 && typeof res === 'string') {
          throw new CartException(truncateApiError(res), 'info');
        } else if (res?.status === 404) {
          throw new CartException('Unable to retrieve recharge checkout', 'critical');
        }
        checkoutUrl = res?.rechargeCheckout;
      }
      window.location.assign(checkoutUrl);
    } else if (checkoutType === CheckoutType.embedded) {
      // If embed, add EmbeddedCheckout.vue to default.vue
      cartStore.SET_EMBED_SHOW(true);
    } else if (checkoutType === CheckoutType.custom) {
      const localePath = useLocalePath();
      // const route = useRoute();
      await navigateTo(localePath('/checkout'));
    }
  } catch (error: any) {
    useHandleCartError({
      error,
      level: 'critical',
      tags: [{ 'customer-flow': 'checkout' }],
      context: { key: 'checkout', data: { checkoutUrl, customerId, checkoutType } },
    });
    cartStore.SET_CART_ERROR(error);
    Promise.resolve(error);
  }
}

/* eslint n/handle-callback-err: ['warn', 'error'] */
/* eslint @typescript-eslint/no-unused-vars: 'warn' */
export function useHandleCartError({
  error,
  level,
  tags,
  context,
}: {
  error: Error;
  level?: any;
  tags?: any;
  context?: any;
}) {
  const customerStore = useCustomerStore();
  const geoStore = useGeodataStore();
  const customerData = customerStore?.customer;
  const customerEmail = customerData?.email;
  const geo = geoStore?.geo;
  const ip = geo?.ip;
  const user = ip || customerEmail ? { ip, customerEmail } : undefined;
  if (!tags || tags?.length === undefined) {
    tags = [{ 'customer-flow': 'cart' }];
  }
  const { $sentry } = useNuxtApp();
  const exception: any = { $sentry, error, user, tags, level, context };
  sentryScopedException(exception);
}
