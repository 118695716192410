import * as Sentry from '@sentry/browser';
// const config = useRuntimeConfig(); // TODO: find workaround - runtime config will crash since it can't be accessed in every cycle
// const DEBUG = config?.public?.debug === 'true';
const DEBUG = false;
// export type TagKey = 'customer-flow' | 'test'// | xxx |
export type TagValue = 'cart' | 'account' | 'checkout' | 'coupon';

export type Context = {
  key: string;
  data: { [index: string]: string };
};

export type Breadcrumb = {
  category: string;
  message: string;
  data: Array<{ [index: string]: string }>;
  level: Sentry.Severity;
};

export type ScopedException = {
  error: Error;
  $sentry: typeof Sentry;
  tags?: Array<{ [index: string]: TagValue }>;
  user?: { email: string; ip_address: string };
  level?: 'fatal' | 'critical' | 'error' | 'warning' | 'log' | 'info' | 'debug';
  context?: Context;
  breadcrumb?: Breadcrumb;
};

export type SentryError =
  | Error
  | {
      name: string;
      message: string;
      level?: ScopedException['level'];
    };

// export const cartNotSet = 'Cart not set';

// export const cartNotSet = 'Cart not set';

// const commonErrorMessages = [
//   cartNotSet,
// ]

export function errorCatchCB(err: any, debugOverride = false, hardStop = false) {
  if (hardStop) return;
  if (DEBUG || debugOverride) {
    if (Array.isArray(err)) return console.error(...err); // eslint-disable-line no-console
    return console.error(err); // eslint-disable-line no-console
  }
}

export function truncateApiError(res: string, _debugOverride = false): string {
  const reqString = res.replace(/^Error: /, '');
  const reqError = DEBUG ? reqString : reqString.split('\n')[0];
  return reqError;
}

export function truncateError(error: any) {
  const preParsed = error?.stack || error?.message || error;
  return preParsed?.split('\n')[0];
}

export function sentryScopedException({
  error,
  $sentry,
  tags,
  user,
  level,
  context,
  breadcrumb,
}: ScopedException): void {
  const sentryError: SentryError = error instanceof Error === true ? error : new Error(error.message, { cause: error });
  $sentry.withScope(function (scope: any) {
    if (tags && tags.length > 0) {
      tags.forEach((tag) => {
        const key = Object.keys(tag)[0];
        const value = Object.values(tag)[0];
        if (key && value) scope.setTag(key, value);
      });
    }
    if (context) scope.setContext(context.key, context.data);
    if (breadcrumb) scope.addBreadcrumb(breadcrumb.category, breadcrumb.message, breadcrumb.data, breadcrumb.level);
    if (user) scope.setUser(user);
    if (level) scope.setLevel(level);
    $sentry.captureException(sentryError);
  });
}
