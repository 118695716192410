export enum CookieKeys {
  Cart = '_solocartses',
  User = '_soloses',
  Api = '_sapidsran',
}

// Cookie
export const setCookie = function (
  cookieName: string,
  cookieValue: string,
  daysUntilExpiration: number,
  secure = true,
  domain?: string,
  path = '/'
) {
  const date = new Date();
  date.setTime(date.getTime() + daysUntilExpiration * 24 * 60 * 60 * 1000);
  const expires = ';expires=' + date.toUTCString();
  let cookieStr = `${cookieName}=${cookieValue}${expires};path=${path}`;
  if (secure) cookieStr = `${cookieStr};Secure`;
  if (domain) cookieStr = `${cookieStr};domain=${domain}`;
  document.cookie = cookieStr;
};
export const getCookie = function (name: string, serverCookies: string | null = null) {
  try {
    // const re = new RegExp(`(?<=${name}=)[^;]*`);
    const cookies = serverCookies || (process.client && document?.cookie) || '';
    const cookiesArr = cookies?.split('; ');
    // const matches = cookies.match(re);
    // return matches ? matches[0] : undefined;
    const foundCookie: string | undefined = cookiesArr?.find((row: string) => row.startsWith(name)) || undefined;
    const wantedCookie: string | undefined =
      foundCookie && foundCookie?.split('=')[1] ? foundCookie.split('=')[1] : undefined;
    return wantedCookie;
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return undefined;
  }
};

export const deleteCookie = function (name: string, domain?: string, path = '/') {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${domain ? `domain=${domain};` : ''}path=${path};`;
};

export function isCookieTrue(cookieName: string) {
  const cookie = getCookie(cookieName);
  return cookie === 'true';
}
