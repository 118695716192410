import { H3Event } from 'h3';
import { getFirstIndex } from '~/util/arrays';
import { extractComponent, menuEdge } from '~/util/contentstack/csHelpers';

type NavMode = 'navigation' | 'search';
interface NavState {
  footer: any;
  header: any;
  headNavsModified: boolean;
  navOpen: boolean;
  desktopNavExpanded: boolean;
  subnavOpenIndex: number | null;
  searchIsOpen: boolean;
  navMode: NavMode;
  lastOpened: string;
  localeModalOpen: boolean;
}

export const useNavStore = defineStore('nav', {
  state: (): NavState => ({
    footer: {},
    header: {},
    headNavsModified: false,
    navOpen: false,
    desktopNavExpanded: false,
    subnavOpenIndex: null,
    searchIsOpen: false,
    navMode: 'navigation',
    lastOpened: '',
    localeModalOpen: false,
  }),

  getters: {
    headerMainNav: (state) => {
      return menuEdge(state?.header?.main_navConnection);
    },
    headerMobileUserNav: (state) => {
      return menuEdge(state?.header?.user_nav_mobileConnection);
    },
    headerUserNav: (state) => {
      return menuEdge(state?.header?.user_nav_desktopConnection);
    },
    selectedMobileNavTitle(state): string {
      if (state.subnavOpenIndex !== null) {
        return this.headerMainNav?.[state.subnavOpenIndex]?.title;
      }
      return 'Main Menu';
    },
    isSearch: (state) => state.navMode === 'search',
  },

  actions: {
    setNavIsOpen(navOpen: boolean) {
      this.navOpen = navOpen;
    },
    setSubnavOpenIndex(subnavOpenIndex: number | null) {
      // on first open, don't add class
      if (this.subnavOpenIndex === null && subnavOpenIndex !== null) {
        this.desktopNavExpanded = false;
      } else if (this.subnavOpenIndex !== null && subnavOpenIndex !== null) {
        this.desktopNavExpanded = true;
      } else {
        this.desktopNavExpanded = false;
      }
      this.subnavOpenIndex = subnavOpenIndex;
    },
    setFooter(footer: any) {
      this.footer = footer;
    },
    setHeader(header: any) {
      this.header = header;
    },
    setLocaleModalOpen(localeModalOpen: boolean) {
      this.localeModalOpen = localeModalOpen;
    },
    navClose() {
      this.setNavIsOpen(false);
    },
    openSearch() {
      document.body.addEventListener('click', this.closeSearch);
      this.navMode = 'search';
      this.navClose();
      this.lastOpened = '';
    },
    closeSearch(e: Event) {
      const target = e.target ? (e.target as HTMLElement) : document.body;
      if (
        (target.closest('.search-opener') || target.closest('.nav-search-container')) &&
        !target.closest('.desktop-open-nav-overlay')
      )
        return;
      this.algoliaClose();
      document.body.removeEventListener('click', this.closeSearch);
    },
    algoliaClose() {
      this.navMode = 'navigation';
    },
    toggleSearch() {
      this.searchIsOpen = !this.searchIsOpen;
    },
    navResetter() {
      this.navClose();
      this.lastOpened = 'navigation';
    },
    handleNavDropdown() {
      if (!this.isSearch) {
        this.setNavIsOpen(!this.navOpen);
      }
    },
    toggleMenu(i: number) {
      if (this.subnavOpenIndex === i) {
        this.setSubnavOpenIndex(null);
        return;
      }
      this.setSubnavOpenIndex(i);
    },
    closeNavs(e: Event) {
      const target = e.target ? (e.target as HTMLElement) : document.body;
      if (
        (target.closest('.nav-container') || target.closest('.menu-dropdown-controls')) &&
        !target.closest('.desktop-open-nav-overlay')
      )
        return;
      this.setSubnavOpenIndex(null);
    },
    setClpPlpTest(clpPlpTest = false) {
      if (clpPlpTest) {
        const firePitsUrl = '/plp/the-essential-fire-pit-collection';
        const header = this.header;
        const firePitsMenuEdge = menuEdge(header?.main_navConnection);
        const firePitsColumnNav = getFirstIndex(firePitsMenuEdge);
        const firePitsFirstCol = getFirstIndex(firePitsColumnNav?.link);
        const firePitsHeadNav = extractComponent(firePitsFirstCol?.internal_linkConnection);
        if (firePitsHeadNav) firePitsHeadNav.url = firePitsUrl;
        if (firePitsFirstCol?.link?.length > 0) {
          const lastFireNav = firePitsFirstCol.link[firePitsFirstCol.link.length - 1];
          const navNode = extractComponent(lastFireNav?.internal_linkConnection);
          if (navNode) navNode.url = firePitsUrl;
        }
      }
    },
    modifyHeadMainNavs(dyTopLevelTargetNames: string[]) {
      const topLevelTargetNames = dyTopLevelTargetNames || [];
      if (topLevelTargetNames.length === 0 || this.headNavsModified) return;
      this.headNavsModified = true;
      // split dy target names by / and use to determine if nested?
      const topLevelTargetIndices = [];
      const mainNavs = this.header?.main_navConnection?.edges?.[0]?.node?.link || [];
      for (let i = 0; i < mainNavs.length; i++) {
        const currentNav = mainNavs[i];
        if (topLevelTargetNames.includes(currentNav?.title?.trim())) {
          topLevelTargetIndices.push(i);
        }
        if (topLevelTargetIndices.length === dyTopLevelTargetNames.length) break;
      }

      let indexShifter = 0;
      topLevelTargetIndices.forEach((index) => {
        const targetIndex = index - indexShifter;
        mainNavs.splice(targetIndex, 1);
        indexShifter++;
      });
    },
    async getFooter({ event = undefined, query }: { event?: H3Event; query?: { locale?: string } }) {
      const $cfFetch = cfFetchBuilder(event);
      let url = `/api/contentstack/footer`;
      if (query?.locale) {
        url += `?locale=${query.locale}`;
      }
      const footer: any = await $cfFetch(url, {
        method: 'GET',
      });
      return footer?.data?.all_footer || {};
    },

    async getHeader({ event = undefined, query }: { event?: H3Event; query?: { locale?: string } }) {
      const $cfFetch = cfFetchBuilder(event);
      let url = `/api/contentstack/header`;
      if (query?.locale) {
        url += `?locale=${query.locale}`;
      }
      const header = await $cfFetch(url, {
        method: 'GET',
      })
        .then((res: any) => {
          const navs = res?.data?.all_header?.items[0] || {};
          if (Object.keys(navs).length === 0) {
            throw new Error('No navs found!');
          }
          return navs;
        })
        .catch((err: any) => {
          console.error(err); // eslint-disable-line no-console
        })
        .then((res: any) => {
          return res?.title === 'Header' ? res : {};
        });
      return header;
    },
  },
});
