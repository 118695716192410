/* eslint-disable camelcase */
import { getCookie } from './storage';
import { ClientDyChooseReq, DYContext, DyChooseReq, DyPageView, EngagementType } from '~/types/dy-types';
import { ServerContext } from '~/types/solo-api';

declare global {
  interface Window {
    DY: { [index: string]: any };
    dyUserEmail: string;
  }
}

export async function engagementEvent(
  eventType: EngagementType,
  slotId?: string,
  eventProperties?: any
): Promise<void> {
  try {
    const _dyid_server = getCookie('_dyid_server');
    const _dyid = getCookie('_dyid');
    const _dyjsession = getCookie('_dyjsession');
    const user = {
      ...(_dyid && { dyid: _dyid }),
      ...(_dyid_server && { dyid_server: _dyid_server }),
    };
    const session = {
      ...(_dyjsession && { dy: _dyjsession }),
    };
    const reqBody = {
      user,
      session,
      engagements: [] as any[],
    };
    if (eventType === 'CLICK') {
      // used for custom click events
      reqBody?.engagements?.push({
        type: 'CLICK',
        ...eventProperties,
      });
    } else if (eventType === 'SLOT_CLICK') {
      // used for recs
      reqBody?.engagements?.push({
        type: eventType,
        slotId,
      });
    }

    // no-cache
    const data: any = await $fetch.raw('/dy-user-engagement', {
      baseURL: '/api',
      method: 'POST',
      body: JSON.stringify(reqBody),
    });

    if (data?.status !== 204)
      throw new Error(`Engagement responded with non 204 status: ${data?.status || 'no status'}`);
  } catch (error: any) {
    console.warn(error); // eslint-disable-line no-console
  }
}

export function setDYContext(type: string, data: string[] | undefined) {
  window.DY = window.DY || {};
  window.DY.recommendationContext = { type, data };
}

export function getDYContext(url: string, homepage: boolean | undefined): DYContext {
  let context: DYContext = 'OTHER';
  switch (true) {
    case homepage:
      context = 'HOMEPAGE';
      break;
    case url.includes('/plp/'):
    case url.includes('/c/'):
      context = 'CATEGORY';
      break;
    case url.includes('/p/') && !url.includes('/community/'):
      context = 'PRODUCT';
      break;
    case url.includes('/cart'):
      context = 'CART';
      break;
    default:
      context = 'OTHER';
      break;
  }
  return context;
}

export function pageTypeByUrl(urlUsed = '', localeUsed = '') {
  const url = urlUsed?.toLowerCase() || '';
  const locale = localeUsed?.toLowerCase() || '';
  const splitUrl = url.split('/');
  const preChecker = url.split('.');

  if (preChecker[preChecker.length - 1] === 'js') {
    return;
  }
  if (splitUrl[0] === '') splitUrl[0] = '/';
  const urlFilter = splitUrl.filter((item) => item !== '' && item !== locale);

  const isHomepage = urlFilter.length === 1 && urlFilter[0] === '/';
  const subPath = urlFilter.length > 1 ? urlFilter[1] : '';
  let pageType = 'OTHER';
  switch (true) {
    case isHomepage:
      pageType = 'HOMEPAGE';
      break;
    case subPath === 'plp':
    case subPath === 'c':
      pageType = 'CATEGORY';
      break;
    // case url.includes('/p/') && !url.includes('/community/'):
    case subPath === 'p':
      pageType = 'PRODUCT';
      break;
    case subPath === 'cart':
      pageType = 'CART';
      break;
    case preChecker[preChecker.length - 1] === 'js':
      pageType = 'NONE';
      break;
    default:
      pageType = 'OTHER';
      break;
  }
  return pageType;
}

export function prepData(context: DYContext, data: DyPageView['data']): string[] | undefined {
  if (context === 'HOMEPAGE') return [''];
  let returnData: any;
  switch (context) {
    case 'CATEGORY': {
      // is sent as an array
      returnData = (data as Record<string, unknown>)?.category || [''];
      break;
    }
    case 'PRODUCT': {
      const sku = (data as any)?.product?.product_offerings?.bc_primary_product?.product?.sku || '';
      returnData = [sku];
      break;
    }
    case 'CART': {
      returnData = (data as any)?.products?.map((product: any) => product.sku) || [''];
      break;
    }
    default:
      returnData = data && Array.isArray(data) ? data : [''];
      break;
  }
  return returnData;
}

export function sha256Email(email: string | undefined): Promise<string> | undefined {
  if (email) {
    const encoder = new TextEncoder();
    const data = encoder.encode(email.toLowerCase().trim());
    return crypto.subtle.digest('SHA-256', data).then((buffer) => {
      return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    });
  }
}

export function setDYUserEmail(email: string | undefined) {
  if (email) {
    window.dyUserEmail = email;
  }
}

export function dyCartData({ cartProducts = [] as any[], isClient = true }): { cartData: any[]; skuData: any[] } {
  const cartData: any[] = [];
  const skuData: any[] = [];
  cartProducts?.forEach((cartProduct: any) => {
    if (+(cartProduct?.price?.regular || 0) > 0) {
      if (isClient)
        cartData.push({
          productId: cartProduct.sku,
          quantity: cartProduct.qty,
          itemPrice: cartProduct?.price?.regular,
        });
      skuData.push(cartProduct.sku);
    }
  });
  return { cartData, skuData };
}

export async function dyChooseCall({
  pageType = undefined as string | undefined,
  pageData = undefined as string[] | undefined,
  selectorGroups = ['headless-site'],
  extraData = undefined as ServerContext,
} = {}) {
  try {
    const URL = useRequestURL();
    const searchParams = new URLSearchParams(URL.search.replace('?', ''));
    const dyApiPreview = searchParams.get('dyApiPreview');
    const _dyid_server = extraData?._dyid_server || process.client ? getCookie('_dyid_server') : null;
    const _dyid = extraData?._dyid || process.client ? getCookie('_dyid') : null;
    const _dyjsession = extraData?._dyjsession || process.client ? getCookie('_dyjsession') : null;
    const user: ClientDyChooseReq['user'] = {
      ...(_dyid && { dyid: _dyid }),
      ...(_dyid_server && { dyid_server: _dyid_server }),
    };
    const session: ClientDyChooseReq['session'] = { ...(_dyjsession && { dy: _dyjsession }) };
    // TODO: find way to extract pageType and pageData, likely use 'extraData' to pass in after other CTS calls
    const type = pageType || (process.client && window?.DY?.recommendationContext?.type) || 'OTHER';
    const data = pageData || (process.client && window?.DY?.recommendationContext?.data) || [''];
    const reqBody: ClientDyChooseReq | any = {
      ...(selectorGroups && {
        selector: {
          groups: selectorGroups,
          ...(dyApiPreview && { preview: { ids: [dyApiPreview] } }),
        },
      }),
      context: {
        page: {
          type,
          data,
          location: extraData?.estimatedLocation || (process.client && window?.location?.href) || '',
        },
        ...(process.client && { device: { userAgent: navigator?.userAgent || '' } }),
      },
      options: {
        isImplicitPageview: false,
        returnAnalyticsMetadata: true,
      },
      user,
      session,
    };
    const reqUrl = `/dy-choose${dyApiPreview ? `?dyApiPreview=${dyApiPreview}` : ''}`;
    const body = JSON.stringify(reqBody);

    // no-cache
    const resData = await $fetch(reqUrl, {
      method: 'POST',
      baseURL: '/api',
      body,
    });

    return resData || {};
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return {};
  }
}

// DO NOT MODIFY THIS FUNCTION WITHOUT MODIFYING /webapp/server/utils/dy-workaround.ts
// Duped to avoid breaking HMR, and maybe something else
export function buildChooseReqBody({
  eventBody = {} as any,
  eventHeaders = {} as any,
  eventCookies = {} as any,
  eventQuery = {} as URL['searchParams'],
  config = {} as any,
}) {
  const { _dyid, _dyid_server, _dyjsession } = eventCookies;
  const { dyApiPreview } = eventQuery as unknown as { dyApiPreview?: string };
  const userAgent = eventHeaders?.['user-agent'];
  const ipAddress = eventHeaders?.['cf-connecting-ip'] || eventHeaders?.['x-forwarded-for'];

  const user = {
    ...(_dyid && { dyid: _dyid }),
    ...(_dyid_server && { dyid_server: _dyid_server }),
  };
  const session = { ...(_dyjsession && { dy: _dyjsession }) };
  eventBody.context = {
    // page: {
    //   // type: getDYContext(urlStructure),
    //   type: 'HOMEPAGE',
    //   data: [''],
    //   // location,
    //   location: 'http://localhost:3000/en-us',
    // },
    ...(eventBody?.context || {}),
    device: {
      ...((eventBody.context?.device && { userAgent: eventBody.context?.device }) || {}),
      ...(userAgent && { userAgent }),
      ip: ipAddress,
      // ip: testIpAddresses.newYork,
    },
  };

  const reqBody: DyChooseReq = {
    selector: {
      groups: [...(eventBody?.selector?.groups || ['headless-site'])],
    },
    context: eventBody?.context,
    user,
    session,
    options: {
      isImplicitPageview: false,
      returnAnalyticsMetadata: true,
    },
    ...(eventBody?.user && { user: eventBody.user }),
    ...(eventBody?.session && { session: eventBody.session }),
  };

  // console.log(JSON.stringify(reqBody, null, 2));

  if (dyApiPreview) {
    reqBody.selector = {
      ...reqBody.selector,
      preview: {
        ids: [dyApiPreview],
      },
    };
  }

  if (config.public.environment !== 'production') {
    const newGroups =
      reqBody.selector?.groups?.map((group: string) => group.replace('headless-site', 'campaign-test')) || [];
    reqBody.selector.groups = reqBody?.selector?.groups?.concat(newGroups);
  }
  return {
    reqBody,
    _dyid: reqBody.user?.dyid,
    _dyid_server: reqBody.user?.dyid_server,
    _dyjsession: reqBody.session?.dy,
  };
}
