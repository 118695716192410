import { Order } from '@solo-stove/types/bigcommerce';

export interface Customer {
  customer_group_id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  id: number;
}
export interface CustomerState {
  customer: null | Customer;
  loggedIn: boolean;
  loginCred: any | null;
  orders: Order[] | null;
  customerError: any | null;
  loading: boolean;
  isMailSent: boolean;
  isPasswordUpdated: boolean;
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    customer: null,
    loggedIn: false,
    loginCred: null,
    orders: null,
    customerError: null,
    loading: false,
    isMailSent: false,
    isPasswordUpdated: false,
  }),

  actions: {
    setCustomer(customer: CustomerState['customer']) {
      // TODO - verify that Vue.set for objects does not have regression here
      this.customer = customer;
    },
    setLoggedIn(loggedIn: boolean) {
      this.loggedIn = loggedIn;
    },
    setLoginCred(loginCred: any) {
      this.loginCred = loginCred;
    },
    setOrders(orders: Order[] | null) {
      // TODO - verify that Vue.set for objects does not have regression here
      this.orders = orders;
    },
    setError(error: any) {
      this.customerError = error;
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setIsMailSent(isMailSent: boolean) {
      this.isMailSent = isMailSent;
    },
    setIsPasswordUpdated(isPasswordUpdated: boolean) {
      this.isPasswordUpdated = isPasswordUpdated;
    },
  },
});
