// https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543
import {
  init,
  BrowserTracing,
  createTracingMixins,
  attachErrorHandler,
  vueRouterInstrumentation,
  setUser,
  addBreadcrumb,
  withScope,
  captureException,
  setContext,
  setTag,
} from '@sentry/vue';
import { CaptureContext, Breadcrumb, User, Scope } from '@sentry/types';

/**
 * usage:
 * const { $sentry } = useSentry();
 * ...
 * $sentry.captureException(err);
 */
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const { vueApp } = nuxtApp;

  const { backupUrl, baseUrl, apiBaseURL, allowLocalDev } = config?.public || {};

  const tracePropagationTargets: any[] = [];

  if (backupUrl) tracePropagationTargets.push(backupUrl);
  if (baseUrl) tracePropagationTargets.push(baseUrl);
  if (apiBaseURL) tracePropagationTargets.push(apiBaseURL);
  if (allowLocalDev) tracePropagationTargets.push(allowLocalDev);

  init({
    app: [vueApp],
    dsn: config?.public?.sentryDsn,
    release: `${config?.public?.sentryProjectName}@${config?.public?.projectVersioning}`,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(nuxtApp.$router as any),
        tracePropagationTargets,
      }),
    ],
    ignoreErrors: ['client.recordDroppedEvent is not a function'],
    logErrors: true, // Note that this doesn't seem to work with nuxt 3 - potentially outdated comment
    tracesSampleRate: config?.public?.environment === 'production' ? 0.005 : 0.2, // Sentry recommends adjusting this value in production
    debug: config?.SENTRY_ENABLE_DEBUG || false, // Enable debug mode
    environment: config?.public?.environment || 'devevelopment',

    // beforeSend intercepts regular sentry events, and allows you to modify them before they are sent to sentry - null for no event sent
    beforeSend(event, hint) {
      const originalException = hint?.originalException as any;
      const matchedFilters = originalException?.stack?.match(/(https?:\/\/staticw2\.yotpo\.com)/);
      const matchFound = matchedFilters?.length > 0;
      const returningEvent = matchFound ? null : event;
      return returningEvent;
    },
  });

  vueApp.mixin(
    createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  );

  // TODO: look into logErrors option, errors are logged on console and sent to sentry
  attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentry: {
        setContext: (n: string, context: { [key: string]: any } | null) => setContext(n, context),
        setUser: (user: User | null) => setUser(user),
        setTag: (tagName: string, value: string | number | bigint | boolean | symbol | null | undefined) =>
          setTag(tagName, value),
        addBreadcrumb: (breadcrumb: Breadcrumb) => addBreadcrumb(breadcrumb),
        captureException: (exception: any, captureContext?: CaptureContext) =>
          captureException(exception, captureContext),
        withScope: (callback: (scope: Scope) => void) => withScope(callback),
      },
    },
  };
});
