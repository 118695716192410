import { get } from 'lodash-es';
import { defu } from 'defu';
import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm';
import { RegionEnvSettings } from '#region-envs/utils';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const URL = useRequestURL();
    const publicConfig = nuxtApp.$config.public;
    const publicRegionSettings = publicConfig?.regionEnvs?.publicRegionSettings;
    const regionLocales = (publicConfig?.regionLocales || []) as unknown as RegionEnvSettings['regionLocales'];
    const locale = URL.pathname.split('/')[1];
    const regionCode = regionLocales.find((region) => region.locale === locale)?.regionCode;
    const gtmId = get(publicRegionSettings, `${regionCode}.gtmId`);
    const options = defu({ id: gtmId, loadScript: true }, nuxtApp.$config.public.gtm);
    const router = useRouter();

    const vueRouter = options.enableRouterSync && router ? (router as VueGtmUseOptions['vueRouter']) : undefined;
    const pluginOptions: VueGtmUseOptions = {
      ...options,
      vueRouter,
    };

    nuxtApp.vueApp.use(createGtm(pluginOptions));
  }
});
