// 1 prefix required so other plugin events can used after this is inited
import mitt from 'mitt';

// NUXt 2
// const busClient: Plugin = (_ctx, inject) => {
//   inject('bus', {
//     $on: emitter.on,
//     $emit: emitter.emit,
//   });
// };

export default defineNuxtPlugin(() => {
  // nuxtApp.provide('$bus', emitter);
  const emitter = mitt();
  return {
    provide: {
      bus: {
        on: emitter.on,
        emit: emitter.emit,
      },
    },
  };
});
