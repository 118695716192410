export function optionConfigDisplay(configArr: any[]): any[] {
  return configArr?.map((config: any) => {
    const isSurcharge =
      config?.name?.toLowerCase()?.includes('option choice for price') ||
      config?.name?.toLowerCase()?.includes('etching surcharge');
    try {
      const parsedObject = JSON.parse(config?.value);
      return {
        ...config,
        ...(parsedObject?.data && !isSurcharge && { value: parsedObject?.data }),
        ...(!parsedObject?.data &&
          isSurcharge && {
            name: 'Etching Cost',
            value: `+$${config.value}`,
          }),
      };
    } catch {
      return config;
    }
  });
}

export function getBgImageWithOverlay(url: string, showOverlay: boolean) {
  let str = '';
  if (url) str += `url(${url});`;
  if (showOverlay) str += ' linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));';
  return str;
}

// Takes the viewport widths in pixels and the font sizes in rem, requires dom access
export function clampBuilder(
  root: HTMLHtmlElement,
  minWidthPx: number,
  maxWidthPx: number,
  minFontSize: number,
  maxFontSize: number
) {
  const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2));

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxFontSize}rem )`;
}

export function lightOrDark(color: string) {
  const defaultColor = 'dark';
  let r, g, b;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    const colorMatches = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!colorMatches) return defaultColor;
    r = parseInt(colorMatches?.[1]);
    g = parseInt(colorMatches?.[2]);
    b = parseInt(colorMatches?.[3]);
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const regex = color.length > 5 ? /./g : '';
    const numColor = +('0x' + color.slice(1).replace(regex, '$&$&'));

    r = numColor >> 16;
    g = (numColor >> 8) & 255;
    b = numColor & 255;
  }

  if (!r || !g || !b) return defaultColor;
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}
