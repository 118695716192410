/* eslint-disable no-console */
import { MetaTrackEventOptions, MetaApiData, MetaBaseEvent, MetaEventName, metaGetEventData } from './../utils';
import { useFetchApiLayer } from '~/utils/api-layer';
import { baseEventStructure } from '~/util/analytics';
import { useLocaleStore } from '~/store/locale';
import { useGeodataStore } from '~/store/geodata';
import { useCustomerStore } from '~/store/customer';

export function useMetaPixel() {
  const facebookSubmitEvent = async function (data: {
    event: MetaEventName;
    pixelData: MetaBaseEvent;
    apiData: MetaApiData;
  }) {
    const { event, pixelData, apiData } = data;
    const eventId = apiData.event_id;
    apiData.event_name = event;
    const gtm = useGtm();
    if (gtm?.trackEvent) {
      const eventData = baseEventStructure(`meta_${event}`.toLowerCase(), pixelData);
      eventData.data.metaEventName = event;
      eventData.data.metaEventId = eventId;
      eventData.data.noscriptImgQueryParams = pixelData.noscriptImgQueryParams;
      delete pixelData.noscriptImgQueryParams;
      gtm.trackEvent(eventData);
    } else {
      console.warn('GTM not found');
    }
    const body = apiData ? JSON.stringify(apiData) : '';
    const options = {
      method: 'POST',
      body,
    };
    try {
      const req = await useFetchApiLayer(`/fb/events`, options);
      const res = await req.json();
      return res;
    } catch (e: any) {
      return Promise.resolve(e);
    }
  };

  const trackEvent = (eventOptions: MetaTrackEventOptions) => {
    try {
      const { event, eventItems, contentName } = eventOptions;
      const geoStore = useGeodataStore();
      const geo = geoStore?.geo;
      const localeStore = useLocaleStore();
      const currencyCode = localeStore.currencyCode;
      const customerStore = useCustomerStore();
      const customer = customerStore?.customer;
      const { pixelData, apiData } = metaGetEventData({
        event,
        eventItems,
        currencyCode,
        geoData: { ...geo, ...customer },
        contentName,
      });
      facebookSubmitEvent({ event, pixelData, apiData });
    } catch (error) {
      console.error('Error in meta pixel event.', error);
    }
  };
  return { trackEvent };
}
