<script lang="ts" setup>
const props = defineProps({
  scrollWrapper: {
    type: Object as () => HTMLElement | null,
    default: null,
  },
  elementSize: {
    type: Number,
    default: 0,
  },
  axis: {
    type: String,
    default: 'X',
  },
});

const scrollPercentage = ref(0);

const emit = defineEmits(['progressbar-update']);

const scrollBarStyle = computed(() => ({
  width: props.axis === 'Y' ? '100%' : scrollPercentage.value + '%',
  height: props.axis === 'Y' ? scrollPercentage.value + '%' : '100%',
}));

watch(
  () => props.scrollWrapper,
  (newVal, oldVal) => {
    if (oldVal) {
      oldVal.removeEventListener('scroll', setScrollPercentage);
    }
    if (newVal) {
      newVal.addEventListener('scroll', setScrollPercentage);
    }
  }
);

onBeforeMount(() => {
  if (props.scrollWrapper) {
    props.scrollWrapper.addEventListener('scroll', setScrollPercentage);
  }
});

onUnmounted(() => {
  if (props.scrollWrapper) {
    props.scrollWrapper.removeEventListener('scroll', setScrollPercentage);
  }
});

const setScrollPercentage = () => {
  if (!props.scrollWrapper) return;

  const element = props.scrollWrapper;
  const scrollPosition = props.axis === 'Y' ? element.scrollTop : element.scrollLeft;

  scrollPercentage.value = props.elementSize ? (scrollPosition / props.elementSize) * 100 : 0;
  emit('progressbar-update', {
    scrollPercentage: scrollPercentage.value,
  });
};
</script>

<template>
  <div class="progressbar--container">
    <div class="progressbar--progress" :data-percentage="scrollPercentage" :style="scrollBarStyle" />
  </div>
</template>

<style lang="scss" scoped>
.progressbar {
  &--container {
    background: #f4f4f4;
    border-radius: #{local-functions.rem-calc(6)};
    height: #{local-functions.rem-calc(6)};
    width: 100%;
    overflow: hidden;
  }
  &--progress {
    background: $color-primary-500;
  }
}
</style>
