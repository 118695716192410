<template>
  <div class="outer-wrapper">
    <CustomButton
      v-show="enabled"
      :icon-only="true"
      :disabled="scrollPercentage === 0"
      style-name="tertiary"
      style-color="black"
      aria-label="Previous Product Image"
      :class="['left-button', { 'btn-disabled': scrollPercentage === 0 }]"
      :style="scrollPercentage === 0 ? 'opacity: 0' : ''"
      @click="handleScroll('left')"
    >
      <ArrowLeftSLine />
    </CustomButton>
    <CustomButton
      v-show="enabled"
      :icon-only="true"
      :disabled="scrollPercentage === 100"
      style-name="tertiary"
      style-color="black"
      aria-label="Next Product Image"
      :class="['right-button', { 'btn-disabled': scrollPercentage === 100 }]"
      :style="scrollPercentage === 100 ? 'opacity: 0' : ''"
      @click="handleScroll('right')"
    >
      <ArrowRightSLine />
    </CustomButton>
    <div
      ref="scrollWrapper"
      :class="[
        'scroll-wrapper',
        {
          'few-items': fewItems,
          'is-component-section': isComponentSection,
          'cart-modal': cartModal,
          'dy-rec-widget': dyRecWidget,
        },
      ]"
    >
      <div
        ref="scrollContainer"
        :class="['flex scroll-container', { 'few-items': fewItems }, { 'secondary-banner': secondaryBanner }]"
      >
        <slot />
      </div>
    </div>
    <Progressbar
      v-show="enabled"
      :scroll-wrapper="scrollWrapper"
      :element-size="elementSize"
      :axis="scrollAxis"
      @progressbar-update="handleProgressbarUpdate"
    />
  </div>
</template>

<script setup lang="ts">
let observer: ResizeObserver | undefined;

const clickScrollPercentage = ref<number>(25);
const scrollWrapper = ref<HTMLElement | null>(null);
const scrollContainer = ref<HTMLElement | null>(null);
const scrollPercentage = ref(0);
const elementSize = ref(0);
const enabled = ref(false);

const props = defineProps({
  fewItems: {
    type: Boolean,
    default: false,
  },
  secondaryBanner: {
    type: Boolean,
    default: false,
  },
  isComponentSection: {
    type: Boolean,
    default: false,
  },
  cartModal: {
    type: Boolean,
    default: false,
  },
  dyRecWidget: {
    type: Boolean,
    default: false,
  },
  showScrollBar: {
    type: Boolean,
    default: true,
  },
  scrollAxis: {
    type: String,
    default: 'X',
  },
});

onMounted(() => {
  const element = scrollContainer.value;
  if (element) {
    observer = new ResizeObserver(resizeEvent);
    observer.observe(element);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = undefined;
  }
});

const resizeEvent: ResizeObserverCallback = () => {
  const element = scrollContainer.value;
  const clientSize = props.scrollAxis === 'Y' ? element?.clientHeight : element?.clientWidth;
  const scrollSize = props.scrollAxis === 'Y' ? element?.scrollHeight : element?.scrollWidth;
  if (clientSize && scrollSize) {
    elementSize.value = scrollSize - clientSize;
    enabled.value = scrollSize > clientSize && props.showScrollBar;
  }
};

const getScrollDistance = () => {
  const element = scrollContainer.value;
  let clientSize = element?.clientWidth;
  if (!clientSize) clientSize = 0;
  let scrollSize = element?.scrollWidth;
  if (!scrollSize) scrollSize = 0;

  if (scrollSize / 2.1 > clientSize) {
    clickScrollPercentage.value = clientSize;
  } else {
    clickScrollPercentage.value = elementSize.value;
  }
};

const handleScroll = (direction: 'left' | 'right') => {
  const el = scrollWrapper.value;
  if (clickScrollPercentage.value === 25) {
    getScrollDistance();
  }
  if (!el) return;
  if (direction === 'left') el.scrollLeft -= clickScrollPercentage.value;
  else el.scrollLeft += clickScrollPercentage.value;
};
const handleProgressbarUpdate = (updatedProgress: { scrollPercentage: number }) => {
  scrollPercentage.value = updatedProgress.scrollPercentage;
  getScrollDistance();
};
</script>

<style scoped lang="scss">
.outer-wrapper {
  position: relative;
}
.scroll-wrapper {
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  padding-bottom: 0.75rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  // START - ComponentSection.vue
  &.is-component-section:deep() {
    .product-card-container {
      min-width: 300px;
      margin: 1rem 0.75rem;
    }
    @include local-mixins.tablet_and_mobile {
      .scroll-container {
        margin: 0 auto;
      }
      .product-card-container {
        margin: 1rem 0.75rem;
        position: relative;
        min-width: 270px;
        &:last-child {
          padding-right: 0.75rem;
          min-width: 282px;
        }
      }
    }
  }
  &.few-items:deep() {
    .product-card-container {
      max-width: 40%;
    }
  }
  // END - ComponentSection.vue
}
.scroll-container {
  gap: 1rem;
  justify-content: space-between;
  margin: 0 1rem;
  @include local-mixins.desktop {
    margin: 0;
  }
}
.left-button,
.right-button {
  position: absolute;
  top: calc(50% - 12px);
  transform: translateY(-50%);
  z-index: map.get(local-vars.$zindex, 'dropdown');
  @include local-mixins.tablet_and_mobile {
    display: none;
  }
  &.btn-disabled {
    pointer-events: none;
  }
}
.left-button {
  left: 1rem;
}
.right-button {
  right: 1rem;
}
.btn:deep() {
  background-color: rgba(255, 255, 255, 0.8);
  height: #{local-functions.rem-calc(50)};
  width: #{local-functions.rem-calc(50)};
  border-radius: 50%;
  border: none;
  padding: 0;
  :deep(svg) {
    fill: $color-neutral-black;
    margin: 0;
    height: 32px;
    width: 37px;
  }
}
:deep(.progressbar--container) {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  @include local-mixins.tablet_and_mobile {
    margin: 1rem 1rem 0 1rem;
    width: calc(100% - 2rem);
  }
  @include local-mixins.tablet {
    margin: 1rem auto 0 auto;
  }
}
</style>
