import { BrandSettings, CloudinaryAsset } from '@solo-stove/types/contentstack';
import { Cart as BcCart, Discount, Option, GiftCertificate, Coupon } from '@solo-stove/types/bigcommerce';
import { useGiftsStore } from './gifts';
import { useLocaleStore } from './locale';
import { useTestStore } from './test';
import { useRootStore } from './index';
import { deleteCookie, CookieKeys } from '~/util/storage';
import { filterGwpProducts, filterHiddenProducts, filterBoxProducts } from '~/util/bigcommerce';
import { lockBody, unlockBody } from '~/util/eventHandler';

export const soloUserCookie = CookieKeys.Cart;

interface Cart extends BcCart {
  estimatedGeoTax: number;
  subscriptionSubTotal?: number;
  subscriptionTaxTotal?: number;
}

interface CartProduct {
  itemId: string;
  id: number;
  variant_id: number;
  sku: string;
  parentSku: string | null;
  title: string;
  image: string;
  url: string;
  price: {
    regular: number;
    msrp: number;
  };
  qty: number;
  min: number;
  configuration: Option[];
  discounts: Discount[];
  discountAmt: number;
  couponAmt: number;
  urgencyMessage: {
    color_hex_: string;
    message: string;
    svg: string;
  };
  freeShipping: boolean;
}

interface LatestAddition {
  quantity: number;
  product: {
    id: number;
    name: string;
    sku: string;
    variantId: number;
    image: CloudinaryAsset[];
    parentSku: string;
    option_selections?: {
      option_id: number;
      option_value: string | number;
      name: string;
      value: string | number;
      valueId: number;
    }[];
    gift_certificates?: GiftCertificate[];
  };
}

export interface CartState {
  cart: Cart | null;
  products: CartProduct[];
  redirectUrls: Cart['redirect_urls'] | null;
  embedLoaded: boolean;
  embedShow: boolean;
  cartActionInProgress: boolean;
  checkoutInitiatied: boolean;
  cartError: unknown | null;
  couponRes: any;
  newItemAdded: boolean;
  showCurrentCartModal: boolean;
  latestAdditions: LatestAddition[] | null;
  gettingCart: boolean;
  hasSubscriptions: boolean;
}

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    cart: null,
    products: [],
    redirectUrls: null,
    embedLoaded: false,
    embedShow: false,
    cartActionInProgress: false,
    checkoutInitiatied: false,
    cartError: null,
    couponRes: null,
    newItemAdded: false,
    showCurrentCartModal: false,
    latestAdditions: null,
    gettingCart: true,
    hasSubscriptions: false,
  }),
  /**
   * Remove any getters that return state under the same name (eg. firstName: (state) => state.firstName),
   * these are not necessary as you can access any state directly from the store instance
   *
   * If you need to access other getters, they are on "this" instead of using the second argument.
   * Remember that if you are using this then you will have to use a regular function instead of an arrow function.
   * Also note that you will need to specify a return type because of TS limitations, see here for more details
   */
  getters: {
    cartId: (state) => state?.cart?.id,
    coupon(state): Coupon | Cart | null {
      if (state?.cart?.coupons?.[0] && Object.keys(state?.cart?.coupons?.[0]).length > 0) {
        return state.cart.coupons[0];
      } else if (typeof state?.cart?.discount_amount === 'number' && state.cart.discount_amount > 0) {
        return state?.cart as Cart;
      } else {
        return null;
      }
    },
    couponActive() {
      return this.coupon !== null;
    },
    couponDiscount() {
      if (!this.coupon) return 0;
      const discAmt = this.coupon?.discounted_amount || 0;
      return parseFloat(discAmt.toFixed(2));
    },
    couponError(state): string | null {
      return state.couponRes?.status === 400 ? state.couponRes?.message || 'Please enter a valid coupon code.' : null;
    },
    numItemsCart(state): number {
      let totalItems = 0;
      const testStore = useTestStore();
      if (testStore?.giftWithPurchaseTest) {
        this.filteredProducts?.forEach((item) => {
          totalItems += item.qty;
        });
      } else {
        const products = filterHiddenProducts(filterBoxProducts(state.products));
        products?.forEach((item) => {
          totalItems += item?.qty;
        });
      }
      return totalItems;
    },
    totalDiscount(state): number {
      if ((!state.cart?.base_amount || typeof state.cart?.base_amount !== 'number') && !state.hasSubscriptions)
        return 0;
      const basePrice = parseFloat(state.cart.base_amount.toFixed(2));
      let discountTotal = 0;
      (state.cart?.discounts || []).forEach((discount) => {
        const discAmt = discount.discounted_amount;
        discountTotal += discAmt;
      });
      return parseFloat(
        (basePrice + (state.cart?.subscriptionSubTotal || 0) - discountTotal + this.couponDiscount).toFixed(2)
      );
    },
    totalPrice(state): number {
      if ((!state.cart?.base_amount || typeof state.cart?.base_amount !== 'number') && !state.hasSubscriptions)
        return 0;
      let totalPrice = 0;
      const hiddenProductsPrice = state.products?.reduce((acc, item) => {
        if (!this.filteredProducts.includes(item)) {
          acc += item.price?.regular;
        }
        return acc;
      }, 0);
      const digitalProductsPrice = state.cart?.line_items?.digital_items?.reduce((acc, item) => {
        if (!this.filteredProducts.includes(item)) {
          acc += (item.quantity || 0) * (item.sale_price || 0);
        }
        return acc;
      }, 0);
      if (state.cart?.base_amount)
        return parseFloat((this.totalDiscount - hiddenProductsPrice - (digitalProductsPrice || 0)).toFixed(2));
      // if (state.cart?.base_amount) return parseFloat(state.cart?.base_amount?.toFixed(2));
      state.products?.forEach((item) => {
        const regPrice = item?.price?.regular || 0;
        const qty = item?.qty || 0;
        totalPrice += regPrice * qty;
      });
      return parseFloat((totalPrice - hiddenProductsPrice).toFixed(2));
    },
    totalTax(state): number {
      const geoTax = state.cart?.estimatedGeoTax || 0;
      const subTax = state.cart?.subscriptionTaxTotal || 0;
      return parseFloat((geoTax + subTax).toFixed(2));
    },
    totalPlusTax(state): number {
      const geoTax = state.cart?.estimatedGeoTax || 0;
      const subTax = state.cart?.subscriptionTaxTotal || 0;
      const total = this.totalPrice + geoTax + subTax - this.couponDiscount;

      return parseFloat(total.toFixed(2));
    },
    filteredProducts: (state) => {
      const testStore = useTestStore();
      return filterHiddenProducts(
        filterGwpProducts(state?.products, useGiftsStore()?.products, testStore?.giftWithPurchaseTest)
      );
    },
    freeShippingPercent(): number | undefined {
      if (typeof this.freeShipThreshold !== 'number') return undefined;
      const percent = (this.totalPrice / this.freeShipThreshold) * 100;
      if (percent > 100) {
        return 100;
      }
      return parseFloat(percent.toFixed(2));
    },
    freeShippingRemaining(): number | undefined {
      if (typeof this.freeShipThreshold !== 'number') return undefined;
      return parseFloat((this.freeShipThreshold - this.totalPrice).toFixed(2));
    },
    freeShippingProductInCart(): boolean {
      let foundProduct = false;
      const freeShippingProducts = useRootStore()?.freeShippingProducts;
      const products = this.filteredProducts;
      if (freeShippingProducts?.length && products?.length) {
        const foundMatch = products?.find((product: any) => {
          const sku = product?.sku;
          const match = freeShippingProducts?.find((freeShipProd: any) => {
            const variants = freeShipProd?.option_variants?.variations;
            // if the product has variants, check if the sku matches any of the variant skus
            if (variants?.length) {
              return variants?.find((variant: any) => {
                return variant?.bc_variant?.sku === sku;
              });
            }
            // if the product does not have variants, check if the sku matches the product sku
            return sku === freeShipProd?.product_offerings?.bc_primary_product?.product?.sku;
          });
          return match;
        });
        if (foundMatch) foundProduct = true;
      }
      return foundProduct;
    },
    freeShipThreshold(): number | undefined {
      const rootState = useRootStore();
      return (rootState?.brandSettings as BrandSettings)?.free_shipping_threshold;
    },
    freeShippingThresholdMet(): boolean {
      return this.freeShippingPercent === 100;
    },
    freeShippingEnabled(): boolean {
      return !~(this.products || []).findIndex((item) => {
        return item.freeShipping === false;
      });
    },
    tieredShippingEnabled(): boolean {
      return this.freeShippingPercent === 100;
    },
    freeShippingMessaging(): string {
      const localeStore = useLocaleStore();
      const away = `${localeStore?.currencySymbol + this.freeShippingRemaining} away from FREE SHIPPING`;
      const message = this.freeShippingThresholdMet ? 'FREE' : away;

      if (!this.freeShippingEnabled) {
        return 'Calculated at checkout';
      }

      return message;
    },
    freeShippingMessagingATC(): string {
      const localeStore = useLocaleStore();
      const away = `Add ${localeStore?.currencySymbol + this.freeShippingRemaining} more for free shipping`;
      const message = this.freeShippingThresholdMet ? '' : away;
      return message;
    },
    freeShippingReachedMessaging(): string {
      const eligibilityLevel = this.freeShippingThresholdMet ? 'Free Shipping' : 'Discounted Shipping';
      return eligibilityLevel;
    },
  },
  actions: {
    SET_CART(cart: Cart | null) {
      this.cart = cart;
    },
    SET_CART_PRODUCTS(products: CartProduct[]) {
      this.products = products;
    },
    SET_REDIRECTURLS(redirectUrls: Cart['redirect_urls'] | null) {
      this.redirectUrls = redirectUrls;
    },
    SET_EMBED_LOADED(embedLoaded: boolean) {
      this.embedLoaded = embedLoaded;
    },
    SET_EMBED_SHOW(embedShow: boolean) {
      this.embedShow = embedShow;
    },
    setCartActionStatus(status: boolean) {
      this.cartActionInProgress = status;
    },
    setCheckoutInitiatied(status: boolean) {
      this.checkoutInitiatied = status;
    },
    setGetCartStatus(status: boolean) {
      this.gettingCart = status;
    },
    setShowCartModal(show: boolean) {
      if (show) {
        lockBody();
      }
      this.showCurrentCartModal = show;
    },
    closeCartModal() {
      unlockBody();
      this.setShowCartModal(false);
      this.setNewItemAdded(false);
    },
    setNewItemAdded(isNew: boolean) {
      this.newItemAdded = isNew;
    },
    setLatestAdditions(products: LatestAddition[] | null) {
      // Vue.set('latestAdditions', products)
      this.latestAdditions = products;
    },
    setCouponRes(data: any) {
      this.couponRes = data;
    },
    SET_CART_ERROR(cartError: any) {
      this.cartError = cartError;
    },
    setSubscriptions(hasSubscriptions: boolean) {
      this.hasSubscriptions = hasSubscriptions;
    },

    cartSetter(data: {
      cartData: {
        cart: Cart;
        items: CartProduct[];
        redirect_urls: Cart['redirect_urls'];
      };
      hasSubscriptions: boolean;
    }) {
      this.SET_CART(data?.cartData?.cart);
      this.SET_CART_PRODUCTS(data?.cartData?.items);
      this.SET_REDIRECTURLS(data?.cartData?.cart?.redirect_urls);
      this.setSubscriptions(data?.hasSubscriptions);
    },

    resetCart() {
      const cartStore = useCartStore();
      cartStore.$reset();
      // TODO - use Pinia reset function
      const config = useRuntimeConfig();
      const domain = config?.public?.cookieDomain;
      deleteCookie(soloUserCookie, domain);
    },

    async deleteCart() {
      try {
        await Promise.all([
          this.SET_CART(null),
          this.SET_REDIRECTURLS(null),
          this.setLatestAdditions(null),
          this.SET_CART_PRODUCTS([]),
        ]);
      } catch (error: any) {
        useHandleCartError({
          error,
          level: 'warning',
          tags: [{ 'customer-flow': 'cart' }],
          context: { key: 'cart', data: { action: 'deleteCart' } },
        });
      }
    },
  },
});
