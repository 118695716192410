import { getConnection, qualityDefault } from '~/util/connection';

interface ConnectionState {
  fastConnection: boolean;
  quality: number;
  connectionType: string;
}

export const useConnectionStore = defineStore('connection', {
  state: (): ConnectionState => ({
    fastConnection: false,
    quality: qualityDefault,
    connectionType: '',
  }),

  actions: {
    setConnection(fastConnection: ConnectionState['fastConnection']) {
      this.fastConnection = fastConnection;
    },
    setQuality(quality: ConnectionState['quality']) {
      this.quality = quality;
    },
    getConnection() {
      const connection = getConnection();
      this.fastConnection = connection.fast;
      this.quality = connection.quality;
      this.connectionType = connection.connectionType;
    },
  },
});
