<template>
  <NuxtLayout>
    <div class="page-block">
      <h1 class="mgn-btm-1-pt5">{{ statusCode }} - {{ errorMessage }}</h1>
      <CustomButton @click-button="handleError"> Go to Home </CustomButton>

      <div class="page-dynamic-content flex-col">
        <PageSection
          v-for="(section, index) in sections"
          :key="`${index}-section`"
          :section="section"
          render-mode="clp"
        />
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { contentstackRequest } from '~/util/contentstack/api';
import { getFirstIndex } from '~/util/arrays';
import { QueryTypes } from '~/server/utils/contentstack';
import { useRootStore } from '~/store';
import { metaGen } from '~/util/metadata';

const { locale } = useI18n();

const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
});

const event = useRequestEvent();

const { data: asyncData, error: asyncError } = await useAsyncData(async () => {
  const contentStackData = await contentstackRequest(QueryTypes.page, '/error', {
    event,
    query: { locale: locale?.value },
  });
  if (!contentStackData) throw createError({ statusCode: 404, message: 'Page not found' });

  const page = getFirstIndex(contentStackData?.data?.all_page?.items);

  return {
    contentStackData,
    page,
  };
});

useAsyncErrorHandling({
  error: asyncError.value,
  stopFullRender: false,
});

const localePath = useLocalePath();

const rootStore = useRootStore();
useHead({
  ...(metaGen(rootStore?.brandSettings, {
    page_title: 'Error ' + (props.error?.statusCode || ''),
    page_description: props.error?.message || undefined,
    no_index: true,
  }) as any),
});

function getErrorMessage(error: any) {
  if (error?.message) return error.message;
  if (error?.statusCode === 404) return 'Page not found';
  return 'An error occurred';
}

const statusCode = props.error?.statusCode || 500;
const errorMessage = getErrorMessage(props.error);
const sections = computed(() => asyncData?.value?.page?.sections || []);

const handleError = () => clearError({ redirect: localePath('/') });
</script>

<style lang="scss" scoped>
.page-block {
  margin: 0 auto;

  @include local-mixins.desktop {
    max-width: local-functions.rem-calc(1440);
  }
  @include local-mixins.xxl {
    max-width: local-functions.rem-calc(1024);
  }
  @include local-mixins.tablet_and_mobile {
    max-width: calc(100% - local-vars.$mob-margin * 2);
    padding: 0 1rem;
    overflow: auto;
  }
  @include local-mixins.tablet {
    margin: 0 local-functions.rem-calc(120);
  }
}
.page-dynamic-content {
  margin-top: 2rem;
}
</style>
