export const useProductDataStore = defineStore('productData', {
  state: () => ({
    variants: {} as any,
    priceListRecords: {} as any,
    priceListAssignments: [] as any[],
    priceListRecordOverrides: {} as any,
  }),

  actions: {
    initialSetup({ variants, priceListRecords, priceListAssignments }: any) {
      this.variants = variants;
      this.priceListRecords = priceListRecords;
      this.priceListAssignments = priceListAssignments;
    },
    setVariants(variants: any) {
      this.variants = variants;
    },
    setPriceListRecordOverrides(priceListRecordOverrides: any[]) {
      this.priceListRecordOverrides = priceListRecordOverrides;
    },
    getPriceBySku(sku: string) {
      if (!sku) return undefined;
      const priceOverride = this.priceListRecordOverrides?.[sku];
      const price = this.priceListRecords?.[sku];
      return priceOverride || price;
    },
    getOptionsBySku(parentSku: string) {
      if (!parentSku) return undefined;
      return this.variants?.[parentSku];
    },
    getVariantOptionBySku(sku: string, parentSku: string) {
      if (!sku || !parentSku) return undefined;
      const skuOptions = this.getOptionsBySku(parentSku);
      if (typeof skuOptions !== 'undefined')
        return skuOptions.option_values.find((variant: any) => variant.sku === sku);
      else return {};
    },
  },
});
