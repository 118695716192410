import { LocaleHeaderName, RegionEnvSettings, RegionHeaderName, RegionSettingsOptions } from '#region-envs/utils';

export function extractRegionCode(i18nProperties: any) {
  const { localeProperties } = i18nProperties;
  const { regionCode } = localeProperties.value || {
    regionCode: '',
  };
  return regionCode;
}

export function extractRegionalEnvs({
  i18nProperties = {},
  regionCode = '',
  regionEnvs = {} as RegionEnvSettings,
}: RegionSettingsOptions) {
  const region = regionCode || extractRegionCode(i18nProperties);
  const { regionSettings, sharedKeys, publicRegionSettings } = regionEnvs;
  const envRegionSetting = {
    ...(sharedKeys || {}),
    ...(regionSettings?.[region as keyof typeof regionSettings] || {}),
    ...(publicRegionSettings?.[region as keyof typeof publicRegionSettings] || {}),
  };
  return envRegionSetting;
}

export const regionHeaderName: RegionHeaderName = 'x-region-code';
export const localeHeaderName: LocaleHeaderName = 'x-region-locale';
