// many h3 functions are declared as globals, but are unavailable
// when running dev-flare, unclear why, for now we explicitly import.
import { H3Event, H3EventContext, fetchWithEvent } from 'h3';
import { $Fetch } from 'nitropack';

type CFReq = RequestInfo | URL;
type CFInit = RequestInit & { context?: H3EventContext };

export function cfFetchBuilder(providedEvent?: H3Event) {
  if (!providedEvent || !providedEvent?.context?.shouldFireFromEvent) return $fetch;
  const event = providedEvent;
  const eventFire = event?.context?.shouldFireFromEvent;

  function eventContextWrapper<T = $Fetch, D = {}>(
    fetch: T,
    req: RequestInfo | URL,
    init?: RequestInit & { context?: H3EventContext }
  ): Promise<Response & D> {
    const context = {
      ...(init || {}),
      context: event?.context,
    };
    // casting fetch as any since h3 isn't aware that we're using a modified fetch.
    // Likewise the return type is being casted as an extendable Promise due to the modified fetch & return.
    return fetchWithEvent(event, req, context, { fetch: fetch as any }) as unknown as Promise<Response & D>;
  }

  const $nfetchRaw = $fetch.raw;

  const nFetch = (req: CFReq, init?: CFInit) => eventContextWrapper($fetch, req, init);
  // may want to better type the following line to make _data a bit more strongly typed
  nFetch.raw = (req: CFReq, init?: CFInit) => eventContextWrapper<$Fetch['raw'], { _data: any }>($nfetchRaw, req, init);
  nFetch.native = (req: CFReq, init?: CFInit) => eventContextWrapper(fetch, req, init);
  nFetch.create = $fetch.create; // TODO: maybe create wrapper
  // if (eventFire) nFetch.bind(globalThis);
  return eventFire ? nFetch : $fetch;
}
