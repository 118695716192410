import { H3Event } from 'h3';
import { useDyStore } from './dy';

export type FeatureFlag = {
  title: string;
  feature_enabled: boolean;
  feature_sessions: boolean;
  environment_override: string[] | null;
  dy_campaign: {
    campaign_id: string;
    campaign_title: string;
    variation_id: string;
  };
  enabled?: boolean;
};

async function getCTSFeatureFlags({
  event = undefined,
  query,
}: {
  event?: H3Event;
  query?: {
    locale?: string;
  };
}): Promise<any> {
  try {
    const $cfFetch = cfFetchBuilder(event);
    const res = await $cfFetch(`/api/contentstack/feature-flag?locale=${query?.locale}`, { method: 'GET' });
    return res;
  } catch {
    return {};
  }
}

function extractFlagsFromCTS(ctsFlags: any) {
  return ctsFlags?.data?.all_feature_flag?.items || [];
}

interface FeatureFlagState {
  featureFlags: FeatureFlag[];
}

export const useFeatureStore = defineStore('feature', {
  state: (): FeatureFlagState => ({
    featureFlags: [] as FeatureFlag[],
  }),

  getters: {
    // showEtching(state) {
    //   const flag = getFeatureFlagByName('Etching Customization', state.featureFlags);
    //   return flag?.enabled;
    // },
  },

  actions: {
    getFeatureFlagByName(name: string) {
      return this.featureFlags.find((flag: any) => flag.title === name);
    },
    setFeatureFlags(featureFlags: FeatureFlagState['featureFlags']) {
      this.featureFlags = featureFlags;
    },
    setEntryEnabledStatus({ entry, enabled }: { entry: FeatureFlag; enabled: boolean }) {
      // entry.enabled = enabled;
      entry.enabled = enabled;
    },
    setFeatureData() {
      this.setEnabledStatus();
    },
    async getFeatureFlags({ event = undefined, query }: { event?: H3Event; query?: { locale?: string } }) {
      const featureFlags = await getCTSFeatureFlags({ event, query });
      const extractedFlags = extractFlagsFromCTS(featureFlags);
      return extractedFlags;
    },
    async setEnabledStatus() {
      const config = useRuntimeConfig();
      const environment = config?.public.environment;
      for (let i = 0; i < this.featureFlags.length; i++) {
        const flag = this.featureFlags[i];
        const campaignId = flag.dy_campaign?.campaign_id;
        const variationId = flag.dy_campaign?.variation_id;
        // TODO - imprto dy store

        const dyCampaign = await useDyStore().findByCampaignId({ campaignId, variationId, skipVariation: false }); // , { root: true }
        // const dyCampaign = await dispatch(
        //   'dy/findByCampaignId',
        //   { campaignId, variationId, skipVariation: false },
        //   { root: true }
        // );
        const dyEnabled = dyCampaign?.variationInfo?.enabled;
        const enableSwap = flag.feature_sessions ? dyEnabled : flag.feature_enabled;
        const enabledByEnvironment = flag.environment_override?.includes(environment);
        const enabled = enableSwap || enabledByEnvironment;
        this.setEntryEnabledStatus({ entry: flag, enabled });
      }
    },
  },
});
