/* eslint-disable no-console */
import { useGeodataStore } from '~/store/geodata';
import { baseEventStructure } from '~/util/analytics';
import { metaGetIdentifyData, MetaIdentifyData } from '~/modules/meta/runtime/utils';

declare global {
  interface Window {
    fbq: any;
  }
}

export default defineNuxtPlugin({
  name: 'metapixel-plugin',
  enforce: 'post',
  hooks: {
    'page:finish'() {
      const gtm = useGtm();
      const geoStore = useGeodataStore();
      const geoData = geoStore.geo as MetaIdentifyData;
      const identifyData = metaGetIdentifyData(geoData);

      if (gtm?.trackEvent) {
        gtm.trackEvent(baseEventStructure('meta_identify', identifyData));
      } else {
        console.warn('GTM not found');
      }
      const metaPixel = useMetaPixel();
      if (metaPixel?.trackEvent) {
        metaPixel.trackEvent({ event: 'PageView' });
      } else {
        console.warn('MetaPixel not found');
      }
    },
  },
});
