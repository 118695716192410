import { Events } from '~/types/bus-events';
import { useCartStore } from '@/store/cart';
import { useCustomerStore } from '@/store/customer';
import { getCookie, CookieKeys } from '@/util/storage';
import { useCloudinary } from '~/composables/cloudinary';

declare global {
  interface Window {
    sd: { [index: string]: any };
    simonDataEmail: string;
  }
}

// Replaces Person ID with Email if available
const identifyEvent = (email: string) => {
  window.simonDataEmail = email;
};

// Parse the cart items to send to Simon Signal
function parseCartItems(cartProducts: any, baseUrl: string, soloUserCookie: string): [any] {
  // Create cart url with token
  const cartToken = getCookie(soloUserCookie);
  const cartUrl = `${baseUrl}/cart?token=${cartToken}`;

  const cartItems: any = [];
  cartProducts?.forEach((product: any) => {
    const productId = product?.id;
    const variant = product?.variant_id;
    const quantity = product?.qty;
    const productImageUrl = product?.image;
    const productUrl = baseUrl + product?.url;
    const productName = product?.title;
    const price = product?.price?.regular;
    const color = findProductItemColor(product);

    // Add the cart item to the cartItems array
    cartItems.push({
      productId,
      variant,
      quantity,
      productImageUrl,
      productUrl,
      productName,
      price,
      color,
      properties: {
        cartUrl,
      },
    });
  });
  return cartItems;
}

// Find Product Item Color
function findProductItemColor(product: any): string {
  let color = null;
  for (let i = 0; i < product?.configuration?.length; i++) {
    if (product?.configuration[i]?.name === 'Color') {
      color = product?.configuration[i]?.value;
      break;
    }
  }
  return color;
}

function findProductById(product: any, id: number) {
  for (let i = 0; i < product?.length; i++) {
    if (product[i]?.id === id) return product[i];
  }
  return null;
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseUrl;
  const cartStore = useCartStore();
  const customerStore = useCustomerStore();
  const cloudinary = useCloudinary();
  const soloUserCookie = CookieKeys.Cart;

  // Product View Event
  (nuxtApp.$bus as any).on('pageView', (data: Events['pageView']) => {
    if (data.url.includes('/p/')) {
      const image = (data.data as any)?.product?.image;
      const url = data.url;
      const productPrice = (data.data as any)?.product?.product_offerings?.bc_primary_product?.product
        ?.calculated_price;
      const bcProductId = (data.data as any)?.product?.product_offerings?.bc_primary_product?.product?.id;
      const mainImage = cloudinary.generateImageUrl(image?.[0]?.public_id);
      const productTitle = (data.data as any)?.product?.display_title;
      const brandName = config?.public?.brand;

      window?.sd?.track('product_view', {
        productId: bcProductId,
        price: productPrice,
        productName: productTitle,
        brand: brandName,
        productUrl: url,
        productImageUrl: mainImage,
      });
    } else if (data.url.includes('/cart')) {
      const cartProducts = cartStore.products;
      const cartItems = parseCartItems(cartProducts, baseUrl, soloUserCookie);
      window?.sd?.track('cart', {
        cartItems,
      });
    }
  });

  // A2C Event
  (nuxtApp.$bus as any).on('a2c', (data: any) => {
    const cartData: any = data[0];
    const cartProducts = cartStore.products;
    const addedItem = cartProducts[cartProducts?.length - 1];
    const isVariant = cartData?.hasVariants;
    const quantity = cartData?.quantity;
    const productId = cartData?.product?.product_offerings?.bc_primary_product?.product?.id;
    const productName = cartData?.product?.display_title;
    const variant = isVariant ? cartData?.product?.variantId : 'false';
    const price = isVariant
      ? cartData?.variant?.calculated_price
      : data?.[0]?.product?.product_offerings?.bc_primary_product?.product?.calculated_price;
    const sku = isVariant
      ? cartData?.variant?.sku
      : data?.[0]?.product?.product_offerings?.bc_primary_product?.product?.sku;
    const color = findProductItemColor(addedItem);
    const cartItems = parseCartItems(cartProducts, baseUrl, soloUserCookie);

    // Create cart url with token
    const cartToken = getCookie(soloUserCookie);
    const cartUrl = `${baseUrl}/cart?token=${cartToken}`;

    window?.sd?.track('add_to_cart', {
      productId,
      variant,
      quantity,
      price,
      productName,
      color,
      properties: { sku, cartUrl },
      productUrl: baseUrl + addedItem?.url,
      productImageUrl: addedItem?.image,
      cartItems,
    });
  });

  // Update Cart Event
  (nuxtApp.$bus as any).on('updateCart', (data: any) => {
    const cartData: any = data;
    const cartProducts = cartStore.products;
    const updatedItem = findProductById(cartProducts, cartData?.product_id);
    const isVariant = updatedItem?.variant_id !== undefined;
    const quantity = cartData?.quantity;
    const previousQuantity = cartData?.previousQty;
    const productId = updatedItem?.id;
    const productName = updatedItem?.title;
    const variant = isVariant ? updatedItem?.variant_id : 'false';
    const price = updatedItem?.price?.regular;
    const color = findProductItemColor(updatedItem);
    const cartItems = parseCartItems(cartProducts, baseUrl, soloUserCookie);

    // Create cart url with token
    const cartToken = getCookie(soloUserCookie);
    const cartUrl = `${baseUrl}/cart?token=${cartToken}`;

    window?.sd?.track('update_cart', {
      productId,
      variant,
      quantity,
      previousQuantity,
      price,
      productName,
      color,
      productUrl: baseUrl + updatedItem?.url,
      productImageUrl: updatedItem?.image,
      cartItems,
      properties: { cartUrl },
    });
  });

  // Remove From Cart Event
  (nuxtApp.$bus as any).on('removeItem', (data: any) => {
    const itemData: any = data;
    const cartProducts = cartStore.products;
    const productId = itemData?.id;
    const variant = itemData?.variant_id || 'false';
    const productName = itemData?.title;
    const price = itemData?.price?.regular;
    const quantity = itemData?.qty;
    const sku = itemData?.sku;
    const productImageUrl = itemData?.image;
    const cartItems = parseCartItems(cartProducts, baseUrl, soloUserCookie);

    // Create cart url with token
    const cartToken = getCookie(soloUserCookie);
    const cartUrl = `${baseUrl}/cart?token=${cartToken}`;

    window?.sd?.track('remove_from_cart', {
      productId,
      variant,
      price,
      quantity,
      productName,
      properties: { sku, cartUrl },
      productImageUrl,
      productUrl: baseUrl + itemData?.url,
      cartItems,
    });
  });

  // Abandoned Cart Event
  (nuxtApp.$bus as any).on('abandonedCart', (data: any) => {
    // Create cart url with token
    const cartUrl = `${baseUrl}/cart?token=${data.token}`;

    window?.sd?.track('custom', {
      event: 'abandoned_cart',
      properties: {
        cartUrl,
      },
    });
  });

  // Registration Event
  (nuxtApp.$bus as any).on('userRegistered', (data: any[]) => {
    const customerData = customerStore.customer;
    const userData: any = data[0];
    const email = userData?.email;
    const name = userData?.first_name + ' ' + userData?.last_name;
    const firstName = userData?.first_name;
    const lastName = userData?.last_name;
    const userId = customerData?.id || '';

    identifyEvent(email);

    window?.sd?.identify(userId, {
      email,
      name,
      firstName,
      lastName,
      userId,
    });

    window?.sd?.track('registration', {
      email,
      firstName,
      lastName,
    });
  });

  // Email Opt in
  (nuxtApp.$bus as any).on('optIn', (data: any) => {
    const customerData = customerStore.customer;
    const userData: any = data;
    const email = userData?.email;
    const userId = customerData?.id || '';

    window?.sd?.identify(userId, {
      email,
      userId,
    });
  });
});
