import { getCookie } from '~/util/storage';

export function checkDebug() {
  return getCookie('_debugger') === 'megaSafeValue';
}

export function cartNotExistCheck(cartError: any) {
  if ((cartError?.title?.includes('Cart of ID') && cartError?.title?.includes('does not exist')) || cartError?.badJwt) {
    return true;
  }
  return false;
}

export function mapProductAddToCart(cartItems: any) {
  /* eslint-disable camelcase */
  const line_items: any[] = [];
  const gift_certificates: any[] = [];
  // console.log('cartItems pre map', cartItems);
  cartItems?.forEach((item: any) => {
    const giftCertCheck = item?.product?.gift_certificates;
    if (giftCertCheck?.length) {
      gift_certificates.push(giftCertCheck[0]);
      return;
    }
    const optionSelections = item?.product?.option_selections;
    line_items.push({
      quantity: item?.quantity,
      product_id: item?.product?.id,
      variant_id: item?.variantId,
      subData: item?.product?.subData,
      sku: item?.product?.sku,
      parentSku: item?.product?.parentSku,
      ...(item?.product?.variantId && { variant_id: item?.product?.variantId }),
      // Etch
      ...(optionSelections && { option_selections: optionSelections }),
    });
  });
  // console.log('cartItems mapped', line_items);
  return { line_items, gift_certificates };
}

export function cartItemsModifiedMap(cartItems: any, subIntercept: any) {
  return cartItems.map((item: any) => {
    const variant = item?.variant;
    return {
      ...item,
      product: {
        ...(item?.product || {}),
        bc_product: {
          ...(item?.product?.bc_product || {}),
          calculated_price: subIntercept?.price?.regular,
        },
      },
      ...(variant && {
        variant: {
          ...item,
          calculated_price: subIntercept?.price?.regular,
        },
      }),
    };
  });
}
