export function generateId(suffix = '' as string): string {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  const date = new Date().getTime().toString();
  let id = date.slice(date.length - 4);
  for (let i = 0; i < 4; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `comp-${id}${suffix}-v`;
}

export function generateIdNoDate(suffix = '' as string): string {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  let id = Math.random().toString(36).substring(2, 15);
  for (let i = 0; i < 4; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `comp-${id}${suffix}-v`;
}
