<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script>
/**
 * TODO: Try to remove imports once Nuxt is upgraded beyond 3.10.x.
 *       As of 3.7.4 this is required (along w/ the disabling of
 *       `experimental.inlineSSRStyles` update in `nuxt.config.ts`).
 * @link https://solobrands.atlassian.net/browse/SST-997
 */
import '@/layouts/custom.vue';
import '@/layouts/default.vue';
</script>
