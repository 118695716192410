import { getFirstIndex } from '~/util/arrays';
import { localeHeaderName } from '~/modules/01-region-envs/utils/region-handler';

type Sku = string | undefined;
type ProductId = number | undefined;
type PId = number | undefined;

type UndefindedParams = {
  id: PId;
  sku: Sku;
  secondSku: Sku;
  product: ProductId;
  secondProduct: ProductId;
};

interface QueryParams {
  sku: Sku;
}
interface IHeaders {
  [key: string]: any;
}

function errorWrapper(condition: boolean, message: string) {
  if (condition) throw new Error(message);
}

async function getProdBySku(headers: IHeaders, { sku }: UndefindedParams) {
  const querySlug = 'sku';
  const capitalizedSlug = querySlug.charAt(0).toUpperCase() + querySlug.slice(1);
  // TODO - fix for variant SKU
  const queryString = querySlug && `/api/bc/product-by-${querySlug}?bc${capitalizedSlug}=${sku}`;
  const bcData: any = queryString && (await $fetch(queryString, { headers }));
  // console.log('getbysku res', bcData?.data);
  const firstBc = getFirstIndex(bcData?.data);

  errorWrapper(!firstBc, 'Unable to retrieve Basic Commerce Data.');
  return firstBc;
}

function parseVariantOptions(bcData: any): any[] {
  const bcDataOptions = bcData?.variants?.length
    ? bcData?.variants?.map((option: any) => {
        return {
          ...option,
          bcId: option?.id,
        };
      })
    : [];
  return bcDataOptions;
}

async function getCtsProductInfo(id: PId, locale: string) {
  const data = id && (await $fetch(`/api/contentstack/ctsProductByBcId?bcId=${id}&locale=${locale}`));
  // console.log('get product info res', data);
  const firstData = getFirstIndex(data?.data?.all_product_v2?.items);
  // console.log('get product info res', firstData);
  errorWrapper(!firstData, 'Unable to retrieve All Product of one.');
  return firstData;
}

function matchVariantChild(product: any, { id, sku }: UndefindedParams) {
  const options = parseVariantOptions(product);
  const activeVariant = options.find((option: any) => {
    return (id && option.id === id) || (sku && option.sku === sku);
  });

  return {
    bcId: activeVariant?.id,
    variant_id: activeVariant?.option_values?.[0]?.id || undefined,
    variant_sku: activeVariant?.sku || undefined,
  };
}

function parseReturnData(product: any, chosenVariant: any = {}) {
  const baseProduct = product?.product_offerings?.bc_primary_product?.product;
  const hasVariants = product?.product_offerings?.product_variations?.length > 1;
  const sku = hasVariants ? chosenVariant?.sku : product?.sku;
  const parentSku = hasVariants ? baseProduct?.sku : null;
  const variantId = hasVariants ? chosenVariant?.id : null;
  // TODO - check for base and variant images
  const image = baseProduct?.images?.length ? baseProduct.images : chosenVariant?.images;
  const mappedProduct = {
    id: baseProduct?.id,
    product: baseProduct,
    hasVariants,
    parentSku,
    sku,
    image,
    variantId,
    quantity: 1,
  };
  return mappedProduct;
}

async function getProduct(
  headers: IHeaders,
  { sku }: UndefindedParams,
  { secondSku, secondProduct } = {} as UndefindedParams
) {
  const bcData = await getProdBySku(headers, {
    sku,
  } as UndefindedParams);
  const variantChosen = matchVariantChild(bcData, {
    id: secondProduct,
    sku: secondSku,
  } as UndefindedParams);
  const locale = headers?.[localeHeaderName];
  const ctsProduct = await getCtsProductInfo(bcData?.id, locale);
  const productData = parseReturnData(ctsProduct, variantChosen);
  return productData;
}

export function callController(headers: IHeaders, { sku }: QueryParams) {
  if (!sku) {
    errorWrapper(true, 'Invalid query parameter structure.');
  }
  return getProduct(headers, { sku } as UndefindedParams);
}
