import { get } from 'lodash-es';
export const qualityDefault = 40;
export const qualityFast = 80;
export const getConnection = function () {
  const connectionType: string = get(navigator, 'connection.effectiveType') || '';
  const fast = connectionType ? parseInt(connectionType) >= 4 : false;
  const quality = fast ? qualityFast : qualityDefault;
  return {
    fast,
    quality,
    connectionType,
  };
};
