import { useCartStore } from '~/store/cart';
import { useDyStore } from '~/store/dy';
// import { useLocaleStore } from '~/store/locale';
import { useCustomerStore } from '~/store/customer';
import { getDYContext, prepData, setDYContext, setDYUserEmail, sha256Email, dyCartData } from '~/util/dy';
import { DyPageView } from '~/types/dy-types';

export default defineNuxtPlugin((nuxtApp) => {
  // only US locale
  // const localeStore = useLocaleStore();
  // localeStore.isUsLocal
  // eslint-disable-next-line no-constant-condition
  if (true) {
    (nuxtApp.$bus as any).on('pageView', async (eventData: DyPageView) => {
      const dyStore = useDyStore();
      const customerStore = useCustomerStore();
      const url = eventData.url;
      const homepage = eventData?.homepage;
      const context = getDYContext(url, homepage);
      const data = prepData(context, eventData.data);
      setDYContext(context, data);
      dyStore.setPageContext(true);
      dyStore.updateDyChoices();
      const isLoggedIn = customerStore.loggedIn;
      if (isLoggedIn) {
        const customerData = customerStore.customer;
        const email = customerData?.email;
        const hashedEmail = await sha256Email(email);
        setDYUserEmail(hashedEmail);
      }
    });

    (nuxtApp.$bus as any).on('a2c', (data: any) => {
      try {
        const cartStore = useCartStore();
        const dyStore = useDyStore();
        const cartProducts = cartStore.products;
        const parsedData = [];

        for (let i = 0; i < data?.length || 0; i++) {
          const isVariant = data[i].hasVariants;
          const id = data[i].product?.variantId || data[i].product?.product_offerings?.bc_primary_product?.product?.id;
          const product = cartProducts?.find((product: any) => {
            return isVariant ? product.variant_id === id : product.id === id;
          });
          if (product) parsedData.push(product);
        }

        const { cartData, skuData } = dyCartData({ cartProducts });

        dyStore.updateRecChoices({ pageData: skuData, selectorGroups: ['headless-site'], pageType: 'CART' });

        parsedData.forEach((product: any) => {
          const quantity = product?.qty || 0;
          const price = +(product?.price?.regular || 0);
          const totalValue = quantity * price;
          const sku = product?.sku || '';

          // TODO: Review - May need to use apiLayer for call here depending on what could be missed by deferring the DY scripts
          if (totalValue && totalValue > 0)
            window?.DY?.API('event', {
              name: 'Add to Cart',
              properties: {
                dyType: 'add-to-cart-v1',
                value: totalValue, // was store.getters['cart/totalPrice'], but docs make it seem like it should just be the total of the current item * qty
                // TODO - currencyCode
                currency: 'USD',
                productId: sku, // Do not change to ID - this needs to be the SKU
                quantity,
                cart: cartData,
              },
            });
        });
      } catch (error: any) {
        console.error(error); // eslint-disable-line no-console
      }
    });
  }
});
