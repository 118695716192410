import { H3Event } from 'h3';
import { QueryTypes } from '~/server/utils/contentstack';

export async function contentstackRequest(
  queryType: QueryTypes,
  path: string,
  {
    query = {},
    event = undefined,
  }: {
    query?: Record<string, string>;
    event?: H3Event;
  }
): Promise<{ [index: string]: unknown } | undefined> {
  try {
    // console.log(`Fetching - ${path} ${locale} - Query Type: ${queryType}`); // eslint-disable-line no-console

    const $cfFetch = cfFetchBuilder(event);
    let reqUrl = `/api/contentstack/${queryType}?url=${path || ''}`;
    if (query?.locale) reqUrl += `&locale=${query?.locale}`;
    if (query?.regionCode) reqUrl += `&regionCode=${query?.regionCode}`;
    const req = await $cfFetch.raw(reqUrl);

    const res: { [index: string]: unknown } | undefined = req?._data || undefined;
    // console.log(`Fetched RES`, res); // eslint-disable-line no-console
    // As of right now, this will only write one cache-header
    // in the response, even though there may be multiple
    // sub-requests.
    if (event) {
      try {
        const originalCacheValue = getResponseHeader(event, soloCacheHeaderName);
        const xCacheValue = req.headers?.get(soloCacheHeaderName);
        if (!originalCacheValue) appendResponseHeader(event, soloCacheHeaderName, `${xCacheValue}`);
      } catch {}
    }
    return res;
  } catch (e) {
    console.error(`Error Fetching - ${path}`, e); // eslint-disable-line no-console
    return Promise.resolve(undefined);
  }
}
