import { merge } from 'lodash-es';
import { FetchResponse } from 'ofetch';
import { Options, ServerContext, SubOptions } from '~/types/solo-api';
import { CookieKeys } from '~/util/storage';

const apiCookie = CookieKeys.Api;
const cartCookie = CookieKeys.Cart;

async function updateHeaders(options: Options, path: string, passes = 0): Promise<void> {
  options.headers = options.headers || {};
  const outOfContext = options?.serverContext?.serverReq;
  const config = outOfContext ? options?.serverContext?.config : useRuntimeConfig();
  const xSendReq = outOfContext ? options?.serverContext?.xSendRec : useCookie(apiCookie)?.value;
  const xSendCrt = outOfContext ? options?.serverContext?.xSendCrt : useCookie(cartCookie)?.value;
  // TODO: get correct way to make this dynamic instead of having to rely on local
  // type env vars to swap to localhost:8787
  const baseURL = config?.public.backupUrl ? config.public.backupUrl : config?.public.apiBaseURL || '';
  options.baseURL = baseURL;
  options.headers['X-Send-Req'] = xSendReq;
  options.headers['X-Send-Crt'] = xSendCrt;

  if (!xSendReq && passes === 0) {
    // no-cache
    const req: FetchResponse<ServerContext> = await $fetch.raw('/x-req', {
      method: 'GET',
      baseURL: '/api',
    });
    const setHeaderCookie = req.headers.get('Set-Cookie');
    if (setHeaderCookie) options.headers['Set-Cookie'] = setHeaderCookie;
    const xReqPayload = req._data;
    const oldServerContext: ServerContext = options?.serverContext || {};

    const newOptions: Options = {
      ...options,
      serverContext: {
        ...oldServerContext,
        serverReq: true,
        ...(xReqPayload?.xSendRec && { xSendRec: xReqPayload.xSendRec }),
      },
    };
    return await updateHeaders(newOptions, path, ++passes);
  }
}

export const useFetchApiLayer = (path: string, apiOptions: Options = {}, isLegacy = false, debug = false) => {
  const interceptOptions = {
    async onRequest({ options }: SubOptions) {
      await updateHeaders(options, path);
      delete options.serverContext;
    },
    // async onResponse({ options, response, request }: SubOptions) {
    //   console.log({response, request, options});
    // },
  };
  const newOptions: any = merge(apiOptions, interceptOptions);

  if (debug) {
    // eslint-disable-next-line no-console
    console.log(apiOptions, 'original options');
    // eslint-disable-next-line no-console
    console.log(newOptions, 'new options');
  }
  // TODO: see if we want to hook this into the CF context
  // this file should be replaced eventually, or absorbed elsewhere
  return isLegacy
    ? $fetch.raw(path, newOptions).catch((error: any) => {
        return error.response;
      })
    : $fetch(path, newOptions);
};
