// Klarna implemention suggests adding script to body tag, not head
export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) return;
  const publicConfig = nuxtApp.$config.public;
  const publicRegionEnvs: any = publicConfig?.regionEnvs?.publicRegionSettings;
  const { regionLocaleProperties } = useRegionRequestSettings();
  const { regionCode } = regionLocaleProperties.value;
  const regionalConfig: any = publicRegionEnvs[regionCode];
  const { klarnaId } = regionalConfig;
  // console.log('KLARNA !!!!!!', klarnaId);
  if (!klarnaId || regionCode === 'US') return;
  const tag = document.createElement('script');
  // tag.src = 'https://na-library.klarnaservices.com/lib.js';
  tag.src = 'https://js.klarna.com/web-sdk/v1/klarna.js';
  tag.async = true;
  tag.setAttribute('data-client-id', klarnaId);
  tag.setAttribute('data-environment', 'production');
  document.body.appendChild(tag);
});
