import { NuxtError } from 'nuxt/app';

// TODO: see if it makes sense using this at the end of the setup script
// where this is used. It causes vue errors, this may or may not be an issue

type OverrideError = string | Partial<NuxtError>;

type AsyncError = {
  error?: Error | null;
  overrideError?: OverrideError | null;
  showOverride?: boolean;
  sentryWanted?: boolean;
  consoleWanted?: boolean;
  stopFullRender?: boolean;
};

export function useAsyncErrorHandling({
  error = null,
  overrideError = {
    statusCode: 404,
    message: 'Page not found',
  },
  showOverride = true,
  sentryWanted = true,
  consoleWanted = true,
  stopFullRender = true,
}: AsyncError) {
  const errorHandled = ref<boolean>(false);

  // If we want acutal error to be displayed, set showOverride to false
  const displayError = showOverride ? overrideError : error;
  const shouldConsoleRun = error && consoleWanted;

  const stopError = computed(() => !error || errorHandled.value);

  function sentryCapture() {
    if (!sentryWanted) return;
    const { $sentry }: any = useNuxtApp();
    $sentry.captureException(error);
  }

  function showHandledError() {
    if (stopError.value) return;
    if (shouldConsoleRun) console.error(error); // eslint-disable-line no-console
    sentryCapture();
    errorHandled.value = true;
    // SPA mode requires showError
    if (process.client) showError(displayError as OverrideError);
    // throw on server & client to stop rendering
    if (stopFullRender) throw createError(displayError as OverrideError);
  }

  showHandledError();

  onBeforeMount(() => {
    showHandledError();
  });
}
