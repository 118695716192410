<script setup lang="ts">
import { Route } from '@intlify/vue-router-bridge';
import { ProductCS } from '../types/contentstack';

const props = defineProps({
  ariaAttrs: {
    type: Object as () => Record<string, unknown>,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  extraClasses: {
    type: (Array as () => string[]) || null,
    required: false,
    default: undefined,
  },
  isMenuItem: {
    type: Boolean,
    default: false,
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  isHashLink: {
    type: Boolean,
    default: false,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String || undefined,
    default: undefined,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  styleColor: {
    type: String,
    default: '',
    validator: (value: string) => {
      const allowedValues = ['white', 'black', ''];
      return allowedValues.includes(value);
    },
  },
  styleName: {
    type: String,
    default: 'primary',
    validator: (value: string) => {
      const allowedValues = ['primary', 'secondary', 'tertiary'];
      return allowedValues.includes(value);
    },
  },
  to: {
    type: String || (Object as () => Route) || (Array as () => ProductCS[]) || undefined,
    default: undefined,
  },
});

const state = reactive({
  classArray: ['btn'],
  prefixId: '',
  svgTitleId: '',
});

const emit = defineEmits<{ (e: 'click-button', value: any): void }>();

const computedClass = computed(() => {
  const { styleName, isSmall, styleColor, extraClasses } = props;
  let newClassArr = [];
  newClassArr.push(
    isSmall ? 'btn-small' : '',
    styleName ? `btn-${styleName}` : '',
    styleColor ? `btn-color-${styleColor}` : '',
    props.isMenuItem ? 'menu-item' : '',
    props.iconOnly ? 'icon-only' : ''
  );
  if (extraClasses) newClassArr = newClassArr.concat(extraClasses);
  return state.classArray.concat(newClassArr);
});
const toComputed = computed(() => {
  if (typeof props.to === 'string') {
    return props.to;
  }
  const to = props.to as unknown as ProductCS[];
  // Product
  return to?.length ? to?.[0]?.url : '';
});

const genericClick = (e: Record<string, unknown>) => {
  emit('click-button', e);
};

let tagName = 'button' as any;
if (props.href) {
  tagName = 'a';
} else if (toComputed.value) {
  tagName = resolveComponent('NuxtLink');
}

defineExpose({ computedClass });
</script>

<template>
  <component
    :is="tagName"
    :class="computedClass"
    :disabled="props.disabled"
    :to="toComputed || undefined"
    :href="props?.href || undefined"
    v-bind="props?.ariaAttrs ? props.ariaAttrs : {}"
    @click="genericClick"
  >
    <div v-if="props.href || toComputed" class="btn-a-children">
      <slot name="icon">
        <template v-if="props.hasIcon">
          <ArrowLeftLine />
        </template>
      </slot>
      <slot />
      <slot name="secondaryIcon" />
    </div>
    <template v-else>
      <slot name="icon">
        <template v-if="props.hasIcon">
          <ArrowLeftLine />
        </template>
      </slot>
      <slot />
      <slot name="secondaryIcon" />
    </template>
  </component>
</template>
