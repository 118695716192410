// https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
let xDown: any = null;
let yDown: any = null;
const swipeThresholdDefault = 150;

function getTouches(evt: any) {
  return (
    evt.touches || // browser API
    evt.originalEvent.touches
  ); // jQuery
}

function resetVals(): void {
  xDown = null;
  yDown = null;
}

function distanceBetweenElements(topEl: any, bottomEl: any) {
  const y1 = topEl?.offsetTop;
  const y2 = bottomEl?.offsetTop;
  return y2 - y1;
}

/**
 * Debounce this function when used, see PDP for example
 * @param id element id
 * @returns True if scrolled past the element referenced, else false
 */
export function scrolledPast(id: string, bodyClass: string | undefined): boolean {
  const topBannerHeight = 102; // allows desktop to revert after scrolling all the way up
  const el = document?.getElementById(id);
  if (el) {
    const currentScrollDistance = window?.scrollY;
    const distBetween = distanceBetweenElements(document.body, el);
    // console.log('DIST BET', distBetween)
    // console.log('SCROLL DIST', currentScrollDistance)
    // console.log('EL TOP DIST', topDist)
    const scrolledPast = currentScrollDistance + topBannerHeight > distBetween;
    if (scrolledPast) {
      el?.classList?.add('scrolled-past');
      if (bodyClass) {
        document.body.classList.add(bodyClass);
      }
    } else {
      el?.classList?.remove('scrolled-past');
      if (bodyClass) {
        document.body.classList.remove(bodyClass);
      }
    }
    return scrolledPast;
  }
  return false;
}

export function scrollRight(
  scrollableDiv: HTMLElement | null,
  percentage: number,
  scrollableContentClass: string
): void {
  const innerContent = scrollableDiv?.querySelector(scrollableContentClass) as HTMLElement;
  if (!innerContent || !scrollableDiv) {
    return; // Exit if the inner content is not found.
  }

  const scrollWidth = innerContent.scrollWidth - scrollableDiv?.clientWidth;
  const scrollAmount = (scrollWidth * percentage) / 100;
  scrollableDiv.scrollLeft += scrollAmount;
}

export function scrollLeft(
  scrollableDiv: HTMLElement | null,
  percentage: number,
  scrollableContentClass: string
): void {
  const innerContent = scrollableDiv?.querySelector(scrollableContentClass) as HTMLElement;
  if (!innerContent || !scrollableDiv) {
    return; // Exit if the inner content is not found.
  }

  const scrollAmount = (innerContent.scrollWidth * percentage) / 100;
  scrollableDiv.scrollLeft -= scrollAmount;
}

export function scrollToPercentage(container: HTMLElement | null, percentage: number): void {
  if (!container) {
    return;
  }
  const scrollableWidth: number = container.scrollWidth - container.clientWidth;
  const scrollAmount: number = (percentage / 100) * scrollableWidth;
  // console.log('scrollAmount', scrollAmount, 'scrollableWidth', scrollableWidth, 'percentage', percentage);
  container.scrollLeft = scrollAmount;
}

export const updateScrollPercentage = (event: Event) => {
  const target = event?.target as HTMLElement;
  const isScrollable = isDivScrollable(target);
  if (!isScrollable) {
    // console.log('not scrollable');
    return undefined;
  }
  // Get the scroll position and the maximum scrollable width
  const scrollLeft = target?.scrollLeft;
  const scrollWidth = target?.scrollWidth - target?.clientWidth;

  // Calculate the percentage scrolled horizontally
  const scroll = (scrollLeft / scrollWidth) * 100;
  return scroll?.toFixed(2);
};

export function getClickPercentageRight(divElement: HTMLElement | null, event: MouseEvent) {
  if (!divElement) {
    return undefined;
  }
  // Get the width of the div element.
  const divWidth = divElement.clientWidth;

  // Calculate the horizontal position of the click relative to the div's width.
  const clickX = event.clientX - divElement.getBoundingClientRect().left;

  // Calculate the percentage based on the click position.
  const clickPercentage = (clickX / divWidth) * 100;

  // Ensure the percentage is within the [0, 100] range.
  // console.log('clickPercentage', clickPercentage);
  return Math.min(100, Math.max(0, clickPercentage)).toString();
}

function isDivScrollable(div: HTMLElement) {
  return div?.scrollWidth > div?.clientWidth;
}

export function scrollToTop(): void {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function scrollToElementById(id: string, e: MouseEvent): void {
  if (e?.preventDefault) e.preventDefault();
  const strippedId = id.replace('#', '');
  const el = document?.getElementById(strippedId);
  const coordinates = el?.getBoundingClientRect();
  const top = coordinates?.top;
  const adjustedTop = top ? top - 85 : 0;
  if (el && coordinates) {
    window.scrollTo({ top: adjustedTop, left: 0, behavior: 'smooth' });
  }
}

export function addBodyClass(className: string): void {
  document.body.classList.add(className);
}

export function removeBodyClass(className: string): void {
  document.body.classList.remove(className);
}

export function lockBody(): void {
  document.body.style.overflow = 'hidden';
  document.body.classList.add('modal-open');
}

export function unlockBody(): void {
  document.body.style.overflow = '';
  document.body.classList.remove('modal-open');
}

/**
 *
 * @param e click event
 * @param el html el
 * @param classname classname to be toggled
 * @returns true if click is outside registered element, else false
 */
export function handleOutsideClick(e: any, el: HTMLElement, classname = 'show'): boolean {
  if (e?.target !== el && !el?.contains(e?.target)) {
    el?.classList?.remove(classname);
    return true;
  }
  return false;
}

export function handleTouchStart(evt: any): void {
  const firstTouch = getTouches(evt);
  if (firstTouch?.length) {
    const first = firstTouch[0];
    xDown = first.clientX;
    yDown = first.clientY;
  }
}

export function handleTouchMove(evt: any, swipeThreshhold: number = swipeThresholdDefault): string | undefined {
  if (!xDown || !yDown || !evt?.touches?.length) {
    return;
  }

  const xUp = evt.touches[0].clientX;
  const yUp = evt.touches[0].clientY;

  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;
  const xAbs = Math.abs(xDiff);
  const yAbs = Math.abs(yDiff);

  // console.log('xdiff', xDiff)
  // console.log('ydiff', yDiff)
  // most significant
  if (xAbs > yAbs && xAbs > swipeThreshhold) {
    if (xDiff > 0) {
      /* right swipe */
      resetVals();
      return 'right';
    } else {
      /* left swipe */
      resetVals();
      return 'left';
    }
  } else if (yDiff > swipeThreshhold) {
    if (yDiff > 0) {
      /* down swipe */
      resetVals();
      return 'down';
    } else {
      /* up swipe */
      resetVals();
      return 'up';
    }
  }
  return undefined;
}

/**
 * Checks if any ancestor of the given element, at any level of depth, has a specific class.
 * @param element - The element to start the search from.
 * @param className - The class name to check for.
 * @returns `true` if an ancestor element with the specific class is found, `false` otherwise.
 */
export function hasAncestorWithClass(element: HTMLElement | null, className: string): boolean {
  while (element && !element.classList.contains(className)) {
    element = element.parentElement;
  }
  return !!element;
}

/**
 * Example usage:
  const mySectionId = 'section1';
  const myEventName = 'sectionVisible';

  triggerEventWhenSectionVisible(mySectionId, myEventName);

  document.getElementById(mySectionId)?.addEventListener(myEventName, () => {
    console.log(`Section '${mySectionId}' is now visible in the viewport.`);
  });
 * 
 * @param sectionId 
 * @param eventName 
 * @returns 
 */
export function triggerEventWhenSectionVisible(sectionId: string, eventName: string) {
  const section = document.getElementById(sectionId);

  if (!section) {
    // console.error(`Section with ID '${sectionId}' not found.`);
    return;
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const callback: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const event = new CustomEvent(eventName);
        section.dispatchEvent(event);
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(section);
}
