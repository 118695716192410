export const useLocaleStore = defineStore('locale', {
  getters: {
    currencyStyle() {
      const i18n = useI18n();
      const { localeProperties } = i18n;
      // Current locale for defined number format
      const numberFormat = i18n.getNumberFormat(localeProperties.value.code)?.currency;
      return numberFormat as any;
    },
    langCode() {
      const i18n = useI18n();
      const { localeProperties } = i18n;
      return localeProperties.value.code;
    },
    currencySymbol(): string {
      return this.currencyStyle?.currencySymbol || '';
    },
    currencyCode(): string {
      return this.currencyStyle?.currency || '';
    },
    isUsLocale(): boolean {
      return this.langCode === 'en-us';
    },
  },
});
