import { ProductV2, CloudinaryAsset } from '@solo-stove/types/contentstack';
import { Product as BCProduct } from '@solo-stove/types/bigcommerce';

export const CheckoutType = {
  custom: 'custom',
  redirected: 'redirected',
  embedded: 'embedded',
};

export interface SubData {
  id: number;
  channel_settings: {
    api: { display: boolean };
    checkout_page: { display: boolean };
    customer_portal: { display: boolean };
    merchant_portal: { display: boolean };
  };
  created_at: string;
  deleted_at: string | null;
  discount_amount: string;
  discount_type: string;
  external_plan_group_id: number | null;
  external_plan_id: number | null;
  external_plan_name: string | null;
  external_product_id: { ecommerce: string };
  external_variant_ids: any[]; // You might want to specify a more specific type here if possible
  has_variant_restrictions: boolean;
  sort_order: number;
  subscription_preferences: {
    apply_cutoff_date_to_checkout: boolean;
    charge_interval_frequency: number;
    cutoff_day_of_month: number | null;
    cutoff_day_of_week: number | null;
    expire_after_specific_number_of_charges: number | null;
    interval_unit: string;
    order_day_of_month: number | null;
    order_day_of_week: number | null;
    order_interval_frequency: number;
  };
  title: string;
  type: string;
  updated_at: string;
}

interface Product {
  id: number;
  name: string;
  sku: string;
  subData?: SubData;
  variantId?: number;
  image: CloudinaryAsset[];
  parentSku: null;
  bc_product?: BCProduct;
}

export type CartObj = {
  previousQty?: number;
  quantity: number;
  product: Product;
  variant_id?: number;
  variant_sku?: string;
  hasVariants?: boolean;
  subData?: SubData;
};

type BcPrimaryProduct = ProductV2['product_offerings']['bc_primary_product']['product'];
interface CartItemProduct extends Omit<BcPrimaryProduct, 'name'> {
  id: number;
  variantId?: number;
  name?: string;
  brand?: string;
  price?: number;
  originalPrice?: number;
  image?: string;
  parentSku?: string;
  category?: string;
  listName?: string;
  subData?: SubData; // Used for subscription products
  option_selections?: {
    option_id: number;
    option_value: string | number;
    name: string;
    value: string | number;
    valueId: number;
  }[]; // Used for Etch products
  gift_certificates?: {
    name: string;
    theme: string;
    amount: number;
    quantity: number;
    sender: {
      name: string;
      email: string;
    };
    recipient: {
      name: string;
      email: string;
    };
    message?: string;
  };
}
type BcVariant = ProductV2['product_offerings']['product_variations'][0]['bc_variant'];

export type AddToCartItem = {
  product: CartItemProduct;
  variant?: BcVariant;
  hasVariants: boolean;
  quantity: number;
  quantityOverride?: number;
  index?: number;
};
