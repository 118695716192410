import { debounce } from 'lodash-es';

declare global {
  interface Window {
    yotpo: any;
    Yotpo: any;
  }
}

let promise: Promise<boolean> | undefined;

async function waitForGlobal(key: string) {
  const ms = 50;
  return await new Promise<boolean>(function check(resolve) {
    if ((window as any)[key]) {
      // Let script finish init
      return setTimeout(() => {
        return resolve(true);
      }, ms);
    }
    setTimeout(function () {
      check(resolve);
    }, ms);
  });
}

const refresh = debounce(async function refresh() {
  try {
    if (promise) {
      return;
    }
    promise = waitForGlobal('Yotpo');
    const exists = await promise;
    if (exists) {
      const api = new (window as any).Yotpo.API((window as any).yotpo);
      api.refreshWidgets();
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  } finally {
    promise = undefined;
  }
}, 50);

export { refresh };
