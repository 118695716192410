interface TestState {
  giftWithPurchaseTest: boolean;
}

export const useTestStore = defineStore('test', {
  state: (): TestState => ({
    giftWithPurchaseTest: false,
  }),

  actions: {
    setGiftWithPurchaseTest(isTest: boolean) {
      this.giftWithPurchaseTest = isTest;
    },
  },
});
