
// @ts-nocheck


export const localeCodes =  [
  "en-al",
  "e-ad",
  "de-at",
  "en-at",
  "en-by",
  "en-be",
  "nl-be",
  "fr-be",
  "en-ba",
  "en-bg",
  "en-hr",
  "en-cz",
  "en-dk",
  "en-ee",
  "en-fi",
  "en-fr",
  "fr-fr",
  "de-de",
  "en-de",
  "en-gr",
  "en-hu",
  "en-ie",
  "en-it",
  "en-lv",
  "en-li",
  "en-lt",
  "en-lu",
  "fr-lu",
  "en-mc",
  "fr-mc",
  "en-nl",
  "nl-nl",
  "en-no",
  "en-pl",
  "en-pt",
  "en-ro",
  "en-cs",
  "en-sk",
  "en-si",
  "en-es",
  "en-se",
  "en-ch",
  "fr-ch",
  "de-ch",
  "en-gb",
  "en-us"
]

export const localeLoaders = {
  "en-al": [{ key: "../lang/en-al.json", load: () => import("../lang/en-al.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_al_json" */), cache: true }],
  "e-ad": [{ key: "../lang/e-ad.json", load: () => import("../lang/e-ad.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_e_ad_json" */), cache: true }],
  "de-at": [{ key: "../lang/de-at.json", load: () => import("../lang/de-at.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_de_at_json" */), cache: true }],
  "en-at": [{ key: "../lang/en-at.json", load: () => import("../lang/en-at.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_at_json" */), cache: true }],
  "en-by": [{ key: "../lang/en-by.json", load: () => import("../lang/en-by.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_by_json" */), cache: true }],
  "en-be": [{ key: "../lang/en-be.json", load: () => import("../lang/en-be.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_be_json" */), cache: true }],
  "nl-be": [{ key: "../lang/nl-be.json", load: () => import("../lang/nl-be.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_nl_be_json" */), cache: true }],
  "fr-be": [{ key: "../lang/fr-be.json", load: () => import("../lang/fr-be.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_fr_be_json" */), cache: true }],
  "en-ba": [{ key: "../lang/en-ba.json", load: () => import("../lang/en-ba.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_ba_json" */), cache: true }],
  "en-bg": [{ key: "../lang/en-bg.json", load: () => import("../lang/en-bg.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_bg_json" */), cache: true }],
  "en-hr": [{ key: "../lang/en-hr.json", load: () => import("../lang/en-hr.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_hr_json" */), cache: true }],
  "en-cz": [{ key: "../lang/en-cz.json", load: () => import("../lang/en-cz.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_cz_json" */), cache: true }],
  "en-dk": [{ key: "../lang/en-dk.json", load: () => import("../lang/en-dk.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_dk_json" */), cache: true }],
  "en-ee": [{ key: "../lang/en-ee.json", load: () => import("../lang/en-ee.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_ee_json" */), cache: true }],
  "en-fi": [{ key: "../lang/en-fi.json", load: () => import("../lang/en-fi.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_fi_json" */), cache: true }],
  "en-fr": [{ key: "../lang/en-fr.json", load: () => import("../lang/en-fr.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_fr_json" */), cache: true }],
  "fr-fr": [{ key: "../lang/fr-fr.json", load: () => import("../lang/fr-fr.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_fr_fr_json" */), cache: true }],
  "de-de": [{ key: "../lang/de-de.json", load: () => import("../lang/de-de.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_de_de_json" */), cache: true }],
  "en-de": [{ key: "../lang/en-de.json", load: () => import("../lang/en-de.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_de_json" */), cache: true }],
  "en-gr": [{ key: "../lang/en-gr.json", load: () => import("../lang/en-gr.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_gr_json" */), cache: true }],
  "en-hu": [{ key: "../lang/en-hu.json", load: () => import("../lang/en-hu.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_hu_json" */), cache: true }],
  "en-ie": [{ key: "../lang/en-ie.json", load: () => import("../lang/en-ie.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_ie_json" */), cache: true }],
  "en-it": [{ key: "../lang/en-it.json", load: () => import("../lang/en-it.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_it_json" */), cache: true }],
  "en-lv": [{ key: "../lang/en-lv.json", load: () => import("../lang/en-lv.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_lv_json" */), cache: true }],
  "en-li": [{ key: "../lang/en-li.json", load: () => import("../lang/en-li.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_li_json" */), cache: true }],
  "en-lt": [{ key: "../lang/en-lt.json", load: () => import("../lang/en-lt.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_lt_json" */), cache: true }],
  "en-lu": [{ key: "../lang/en-lu.json", load: () => import("../lang/en-lu.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_lu_json" */), cache: true }],
  "fr-lu": [{ key: "../lang/fr-lu.json", load: () => import("../lang/fr-lu.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_fr_lu_json" */), cache: true }],
  "en-mc": [{ key: "../lang/en-mc.json", load: () => import("../lang/en-mc.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_mc_json" */), cache: true }],
  "fr-mc": [{ key: "../lang/fr-mc.json", load: () => import("../lang/fr-mc.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_fr_mc_json" */), cache: true }],
  "en-nl": [{ key: "../lang/en-nl.json", load: () => import("../lang/en-nl.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_nl_json" */), cache: true }],
  "nl-nl": [{ key: "../lang/nl-nl.json", load: () => import("../lang/nl-nl.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_nl_nl_json" */), cache: true }],
  "en-no": [{ key: "../lang/en-no.json", load: () => import("../lang/en-no.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_no_json" */), cache: true }],
  "en-pl": [{ key: "../lang/en-pl.json", load: () => import("../lang/en-pl.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_pl_json" */), cache: true }],
  "en-pt": [{ key: "../lang/en-pt.json", load: () => import("../lang/en-pt.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_pt_json" */), cache: true }],
  "en-ro": [{ key: "../lang/en-ro.json", load: () => import("../lang/en-ro.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_ro_json" */), cache: true }],
  "en-cs": [{ key: "../lang/en-cs.json", load: () => import("../lang/en-cs.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_cs_json" */), cache: true }],
  "en-sk": [{ key: "../lang/en-sk.json", load: () => import("../lang/en-sk.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_sk_json" */), cache: true }],
  "en-si": [{ key: "../lang/en-si.json", load: () => import("../lang/en-si.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_si_json" */), cache: true }],
  "en-es": [{ key: "../lang/en-es.json", load: () => import("../lang/en-es.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_es_json" */), cache: true }],
  "en-se": [{ key: "../lang/en-se.json", load: () => import("../lang/en-se.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_se_json" */), cache: true }],
  "en-ch": [{ key: "../lang/en-ch.json", load: () => import("../lang/en-ch.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_ch_json" */), cache: true }],
  "fr-ch": [{ key: "../lang/fr-ch.json", load: () => import("../lang/fr-ch.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_fr_ch_json" */), cache: true }],
  "de-ch": [{ key: "../lang/de-ch.json", load: () => import("../lang/de-ch.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_de_ch_json" */), cache: true }],
  "en-gb": [{ key: "../lang/en-gb.json", load: () => import("../lang/en-gb.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_gb_json" */), cache: true }],
  "en-us": [{ key: "../lang/en-us.json", load: () => import("../lang/en-us.json" /* webpackChunkName: "locale__home_runner_work_solo_brands_scaffolding_solo_brands_scaffolding_webapp_lang_en_us_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en-al",
      "iso": "en-AL",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Albania ",
      "country": "AL",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt86252f69560f549a/649af9acf7bfd1c5418323e2/al.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-al.json"
      ]
    },
    {
      "code": "e-ad",
      "iso": "e-AD",
      "lang": "e",
      "name": "EU",
      "fullName": "English- Andorra ",
      "country": "AD",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltad99d41ab324af8f/649af9ac84a4c75976d8d4ee/ad.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/e-ad.json"
      ]
    },
    {
      "code": "de-at",
      "iso": "de-AT",
      "lang": "de",
      "name": "EU",
      "fullName": "German - Austria",
      "country": "AT",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbc9b38bc1681df7e/649af9acf7bfd13fbb8323de/at.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/de-at.json"
      ]
    },
    {
      "code": "en-at",
      "iso": "en-AT",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Austria",
      "country": "AT",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbc9b38bc1681df7e/649af9acf7bfd13fbb8323de/at.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-at.json"
      ]
    },
    {
      "code": "en-by",
      "iso": "en-BY",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Belarus ",
      "country": "BY",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltf9f69b1edc1934ed/649af9ace64f41bc3342db6e/by.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-by.json"
      ]
    },
    {
      "code": "en-be",
      "iso": "en-BE",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Belgium",
      "country": "BE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-be.json"
      ]
    },
    {
      "code": "nl-be",
      "iso": "nl-BE",
      "lang": "nl",
      "name": "EU",
      "fullName": "Dutch - Belgium",
      "country": "BE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt001a1d63510fa75c/65d38eae5b87e723f859e235/NL_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/nl-be.json"
      ]
    },
    {
      "code": "fr-be",
      "iso": "fr-BE",
      "lang": "fr",
      "name": "EU",
      "fullName": "French - Belgium",
      "country": "BE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/fr-be.json"
      ]
    },
    {
      "code": "en-ba",
      "iso": "en-BA",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Bosnia & Herzegovina ",
      "country": "BA",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8776698bda09bff2/649af9ac24cb3341ef7f34e1/ba.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-ba.json"
      ]
    },
    {
      "code": "en-bg",
      "iso": "en-BG",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Bulgaria",
      "country": "BG",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt09d546df5415afc4/649af9ac05ac380be7aeb98d/bg.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-bg.json"
      ]
    },
    {
      "code": "en-hr",
      "iso": "en-HR",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Croatia ",
      "country": "HR",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbd0e63ad045eb624/649af9acefa20d8da64bd2e2/hr.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-hr.json"
      ]
    },
    {
      "code": "en-cz",
      "iso": "en-CZ",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Czech Republic",
      "country": "CZ",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt03fafb1d6a70ac62/649af9ac7c84d2efafc29cd7/cz.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-cz.json"
      ]
    },
    {
      "code": "en-dk",
      "iso": "en-DK",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Denmark",
      "country": "DK",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt2d893cb46cf3c598/649af9ac4a3adf5afe0527fe/dk.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-dk.json"
      ]
    },
    {
      "code": "en-ee",
      "iso": "en-EE",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Estonia",
      "country": "EE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt218c84ca512886dc/649af9ace9365a0452c35562/ee.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-ee.json"
      ]
    },
    {
      "code": "en-fi",
      "iso": "en-FI",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Finland",
      "country": "FI",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3e63c60c55e25f1e/649af9ac4a3adf57f20527fa/fi.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-fi.json"
      ]
    },
    {
      "code": "en-fr",
      "iso": "en-FR",
      "lang": "en",
      "name": "EU",
      "fullName": "English - France",
      "country": "FR",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8b9a3285d80d70d1/649af9ac84a4c71aa6d8d4e6/fr.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-fr.json"
      ]
    },
    {
      "code": "fr-fr",
      "iso": "fr-FR",
      "lang": "fr",
      "name": "EU",
      "fullName": "French - France",
      "country": "FR",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8b9a3285d80d70d1/649af9ac84a4c71aa6d8d4e6/fr.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/fr-fr.json"
      ]
    },
    {
      "code": "de-de",
      "iso": "de-DE",
      "lang": "de",
      "name": "EU",
      "fullName": "German - Germany",
      "country": "DE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt237e3784fea406c8/649af9acfcb6fd0f775ac1af/de.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/de-de.json"
      ]
    },
    {
      "code": "en-de",
      "iso": "en-DE",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Germany",
      "country": "DE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt237e3784fea406c8/649af9acfcb6fd0f775ac1af/de.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-de.json"
      ]
    },
    {
      "code": "en-gr",
      "iso": "en-GR",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Greece",
      "country": "GR",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc1b655a22be71d9d/649af9ac48bdd24f96103fc8/gr.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-gr.json"
      ]
    },
    {
      "code": "en-hu",
      "iso": "en-HU",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Hungary",
      "country": "HU",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt2c8e682207172d34/649af9acb702426b86cabbf8/hu.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-hu.json"
      ]
    },
    {
      "code": "en-ie",
      "iso": "en-IE",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Ireland",
      "country": "IE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt31eaebc7ebd9a722/649af9ac463b5c285b2d3b2d/ie.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-ie.json"
      ]
    },
    {
      "code": "en-it",
      "iso": "en-IT",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Italy",
      "country": "IT",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt26e18f97459b2927/649af9acfcb6fd85675ac1a3/it.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-it.json"
      ]
    },
    {
      "code": "en-lv",
      "iso": "en-LV",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Latvia",
      "country": "LV",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt9aa22ec966a3e505/649af9ac24cb33d7e37f34c9/lv.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-lv.json"
      ]
    },
    {
      "code": "en-li",
      "iso": "en-LI",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Liechtenstein",
      "country": "LI",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb120bbda0e2814fe/649af9acc411214e6ea19464/li.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-li.json"
      ]
    },
    {
      "code": "en-lt",
      "iso": "en-LT",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Lithuania",
      "country": "LT",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5ebd0caab79fdb4e/649af9acbcdab00d9f3becf0/lt.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-lt.json"
      ]
    },
    {
      "code": "en-lu",
      "iso": "en-LU",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Luxembourg",
      "country": "LU",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt6c18d9ceae9732b6/649af9ac63cca678dfd29084/lu.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-lu.json"
      ]
    },
    {
      "code": "fr-lu",
      "iso": "fr-LU",
      "lang": "fr",
      "name": "EU",
      "fullName": "French - Luxembourg",
      "country": "LU",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt6c18d9ceae9732b6/649af9ac63cca678dfd29084/lu.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/fr-lu.json"
      ]
    },
    {
      "code": "en-mc",
      "iso": "en-MC",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Monaco",
      "country": "MC",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb3a1bfeccbb79d07/649af9ac29ad98317c825d24/mc.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-mc.json"
      ]
    },
    {
      "code": "fr-mc",
      "iso": "fr-MC",
      "lang": "fr",
      "name": "EU",
      "fullName": "French - Monaco",
      "country": "MC",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb3a1bfeccbb79d07/649af9ac29ad98317c825d24/mc.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/fr-mc.json"
      ]
    },
    {
      "code": "en-nl",
      "iso": "en-NL",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Netherlands",
      "country": "NL",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltaae1decd3cb26da2/649af9abeaac9d6c72390577/nl.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-nl.json"
      ]
    },
    {
      "code": "nl-nl",
      "iso": "nl-NL",
      "lang": "nl",
      "name": "EU",
      "fullName": "Dutch - The Netherlands",
      "country": "NL",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltaae1decd3cb26da2/649af9abeaac9d6c72390577/nl.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt001a1d63510fa75c/65d38eae5b87e723f859e235/NL_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/nl-nl.json"
      ]
    },
    {
      "code": "en-no",
      "iso": "en-NO",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Norway",
      "country": "NO",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc0579a6c7eac8e66/649af9acf7bfd1de9d8323c2/no.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-no.json"
      ]
    },
    {
      "code": "en-pl",
      "iso": "en-PL",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Poland",
      "country": "PL",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltff629d7e410981d8/649af9ab96c4d6283aa41cc1/pl.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-pl.json"
      ]
    },
    {
      "code": "en-pt",
      "iso": "en-PT",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Portugal",
      "country": "PT",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltdcc7564bdaa48ad6/649af9ab13bff850842ece66/pt.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-pt.json"
      ]
    },
    {
      "code": "en-ro",
      "iso": "en-RO",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Romania",
      "country": "RO",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt9d934d6d4b227d7c/649af9ab48bdd27f13103fc4/ro.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-ro.json"
      ]
    },
    {
      "code": "en-cs",
      "iso": "en-CS",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Serbia",
      "country": "CS",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt70146176074d25ee/649af9ab7c84d2a62fc29cc3/rs.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-cs.json"
      ]
    },
    {
      "code": "en-sk",
      "iso": "en-SK",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Slovakia",
      "country": "SK",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc49702c6e841afe0/649af9ab84a4c71221d8d4ce/sk.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-sk.json"
      ]
    },
    {
      "code": "en-si",
      "iso": "en-SI",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Slovenia",
      "country": "SI",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blteabb1eb5e927a6d6/649af9abc18599ec660931eb/si.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-si.json"
      ]
    },
    {
      "code": "en-es",
      "iso": "en-ES",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Spain",
      "country": "ES",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltee7aa15af1d8fab3/649af9ac24cb331a157f34dd/es.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-es.json"
      ]
    },
    {
      "code": "en-se",
      "iso": "en-SE",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Sweden",
      "country": "SE",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt60db04aba4f04729/649af9abc411218ceea1945c/se.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-se.json"
      ]
    },
    {
      "code": "en-ch",
      "iso": "en-CH",
      "lang": "en",
      "name": "EU",
      "fullName": "English - Switzerland",
      "country": "CH",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-ch.json"
      ]
    },
    {
      "code": "fr-ch",
      "iso": "fr-CH",
      "lang": "fr",
      "name": "EU",
      "fullName": "French - Switzerland",
      "country": "CH",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/fr-ch.json"
      ]
    },
    {
      "code": "de-ch",
      "iso": "de-CH",
      "lang": "de",
      "name": "EU",
      "fullName": "German - Switzerland",
      "country": "CH",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
      "files": [
        "lang/de-ch.json"
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-GB",
      "lang": "en",
      "name": "EU",
      "fullName": "English - United Kingdom",
      "country": "GB",
      "storeHash": "3js3lsrgjq",
      "regionCode": "EU",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt47f5ca5cca306b19/649af9ace9365a441fc3555a/gb.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-gb.json"
      ]
    },
    {
      "code": "en-us",
      "iso": "en-US",
      "lang": "en",
      "name": "US",
      "fullName": "English - United States",
      "country": "US",
      "storeHash": "b5b4c",
      "regionCode": "US",
      "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blte865b2fe7c05cb50/649af9ab96c4d6554ea41cbd/us.svg?branch=dam_v2",
      "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
      "files": [
        "lang/en-us.json"
      ]
    }
  ],
  "defaultLocale": "en-us",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": ".solostove.com",
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en-us",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://stg.solostove.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en-al",
    "iso": "en-AL",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Albania ",
    "country": "AL",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt86252f69560f549a/649af9acf7bfd1c5418323e2/al.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-al.json"
      }
    ]
  },
  {
    "code": "e-ad",
    "iso": "e-AD",
    "lang": "e",
    "name": "EU",
    "fullName": "English- Andorra ",
    "country": "AD",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltad99d41ab324af8f/649af9ac84a4c75976d8d4ee/ad.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/e-ad.json"
      }
    ]
  },
  {
    "code": "de-at",
    "iso": "de-AT",
    "lang": "de",
    "name": "EU",
    "fullName": "German - Austria",
    "country": "AT",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbc9b38bc1681df7e/649af9acf7bfd13fbb8323de/at.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/de-at.json"
      }
    ]
  },
  {
    "code": "en-at",
    "iso": "en-AT",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Austria",
    "country": "AT",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbc9b38bc1681df7e/649af9acf7bfd13fbb8323de/at.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-at.json"
      }
    ]
  },
  {
    "code": "en-by",
    "iso": "en-BY",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Belarus ",
    "country": "BY",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltf9f69b1edc1934ed/649af9ace64f41bc3342db6e/by.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-by.json"
      }
    ]
  },
  {
    "code": "en-be",
    "iso": "en-BE",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Belgium",
    "country": "BE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-be.json"
      }
    ]
  },
  {
    "code": "nl-be",
    "iso": "nl-BE",
    "lang": "nl",
    "name": "EU",
    "fullName": "Dutch - Belgium",
    "country": "BE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt001a1d63510fa75c/65d38eae5b87e723f859e235/NL_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/nl-be.json"
      }
    ]
  },
  {
    "code": "fr-be",
    "iso": "fr-BE",
    "lang": "fr",
    "name": "EU",
    "fullName": "French - Belgium",
    "country": "BE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5e9893d0cdb06a22/649af9ac29ad984a69825d30/be.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/fr-be.json"
      }
    ]
  },
  {
    "code": "en-ba",
    "iso": "en-BA",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Bosnia & Herzegovina ",
    "country": "BA",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8776698bda09bff2/649af9ac24cb3341ef7f34e1/ba.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-ba.json"
      }
    ]
  },
  {
    "code": "en-bg",
    "iso": "en-BG",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Bulgaria",
    "country": "BG",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt09d546df5415afc4/649af9ac05ac380be7aeb98d/bg.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-bg.json"
      }
    ]
  },
  {
    "code": "en-hr",
    "iso": "en-HR",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Croatia ",
    "country": "HR",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltbd0e63ad045eb624/649af9acefa20d8da64bd2e2/hr.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-hr.json"
      }
    ]
  },
  {
    "code": "en-cz",
    "iso": "en-CZ",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Czech Republic",
    "country": "CZ",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt03fafb1d6a70ac62/649af9ac7c84d2efafc29cd7/cz.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-cz.json"
      }
    ]
  },
  {
    "code": "en-dk",
    "iso": "en-DK",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Denmark",
    "country": "DK",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt2d893cb46cf3c598/649af9ac4a3adf5afe0527fe/dk.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-dk.json"
      }
    ]
  },
  {
    "code": "en-ee",
    "iso": "en-EE",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Estonia",
    "country": "EE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt218c84ca512886dc/649af9ace9365a0452c35562/ee.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-ee.json"
      }
    ]
  },
  {
    "code": "en-fi",
    "iso": "en-FI",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Finland",
    "country": "FI",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3e63c60c55e25f1e/649af9ac4a3adf57f20527fa/fi.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-fi.json"
      }
    ]
  },
  {
    "code": "en-fr",
    "iso": "en-FR",
    "lang": "en",
    "name": "EU",
    "fullName": "English - France",
    "country": "FR",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8b9a3285d80d70d1/649af9ac84a4c71aa6d8d4e6/fr.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-fr.json"
      }
    ]
  },
  {
    "code": "fr-fr",
    "iso": "fr-FR",
    "lang": "fr",
    "name": "EU",
    "fullName": "French - France",
    "country": "FR",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt8b9a3285d80d70d1/649af9ac84a4c71aa6d8d4e6/fr.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/fr-fr.json"
      }
    ]
  },
  {
    "code": "de-de",
    "iso": "de-DE",
    "lang": "de",
    "name": "EU",
    "fullName": "German - Germany",
    "country": "DE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt237e3784fea406c8/649af9acfcb6fd0f775ac1af/de.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/de-de.json"
      }
    ]
  },
  {
    "code": "en-de",
    "iso": "en-DE",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Germany",
    "country": "DE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt237e3784fea406c8/649af9acfcb6fd0f775ac1af/de.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-de.json"
      }
    ]
  },
  {
    "code": "en-gr",
    "iso": "en-GR",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Greece",
    "country": "GR",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc1b655a22be71d9d/649af9ac48bdd24f96103fc8/gr.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-gr.json"
      }
    ]
  },
  {
    "code": "en-hu",
    "iso": "en-HU",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Hungary",
    "country": "HU",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt2c8e682207172d34/649af9acb702426b86cabbf8/hu.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-hu.json"
      }
    ]
  },
  {
    "code": "en-ie",
    "iso": "en-IE",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Ireland",
    "country": "IE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt31eaebc7ebd9a722/649af9ac463b5c285b2d3b2d/ie.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-ie.json"
      }
    ]
  },
  {
    "code": "en-it",
    "iso": "en-IT",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Italy",
    "country": "IT",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt26e18f97459b2927/649af9acfcb6fd85675ac1a3/it.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-it.json"
      }
    ]
  },
  {
    "code": "en-lv",
    "iso": "en-LV",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Latvia",
    "country": "LV",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt9aa22ec966a3e505/649af9ac24cb33d7e37f34c9/lv.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-lv.json"
      }
    ]
  },
  {
    "code": "en-li",
    "iso": "en-LI",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Liechtenstein",
    "country": "LI",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb120bbda0e2814fe/649af9acc411214e6ea19464/li.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-li.json"
      }
    ]
  },
  {
    "code": "en-lt",
    "iso": "en-LT",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Lithuania",
    "country": "LT",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt5ebd0caab79fdb4e/649af9acbcdab00d9f3becf0/lt.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-lt.json"
      }
    ]
  },
  {
    "code": "en-lu",
    "iso": "en-LU",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Luxembourg",
    "country": "LU",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt6c18d9ceae9732b6/649af9ac63cca678dfd29084/lu.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-lu.json"
      }
    ]
  },
  {
    "code": "fr-lu",
    "iso": "fr-LU",
    "lang": "fr",
    "name": "EU",
    "fullName": "French - Luxembourg",
    "country": "LU",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt6c18d9ceae9732b6/649af9ac63cca678dfd29084/lu.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/fr-lu.json"
      }
    ]
  },
  {
    "code": "en-mc",
    "iso": "en-MC",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Monaco",
    "country": "MC",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb3a1bfeccbb79d07/649af9ac29ad98317c825d24/mc.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-mc.json"
      }
    ]
  },
  {
    "code": "fr-mc",
    "iso": "fr-MC",
    "lang": "fr",
    "name": "EU",
    "fullName": "French - Monaco",
    "country": "MC",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltb3a1bfeccbb79d07/649af9ac29ad98317c825d24/mc.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/fr-mc.json"
      }
    ]
  },
  {
    "code": "en-nl",
    "iso": "en-NL",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Netherlands",
    "country": "NL",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltaae1decd3cb26da2/649af9abeaac9d6c72390577/nl.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-nl.json"
      }
    ]
  },
  {
    "code": "nl-nl",
    "iso": "nl-NL",
    "lang": "nl",
    "name": "EU",
    "fullName": "Dutch - The Netherlands",
    "country": "NL",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltaae1decd3cb26da2/649af9abeaac9d6c72390577/nl.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt001a1d63510fa75c/65d38eae5b87e723f859e235/NL_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/nl-nl.json"
      }
    ]
  },
  {
    "code": "en-no",
    "iso": "en-NO",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Norway",
    "country": "NO",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc0579a6c7eac8e66/649af9acf7bfd1de9d8323c2/no.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-no.json"
      }
    ]
  },
  {
    "code": "en-pl",
    "iso": "en-PL",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Poland",
    "country": "PL",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltff629d7e410981d8/649af9ab96c4d6283aa41cc1/pl.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-pl.json"
      }
    ]
  },
  {
    "code": "en-pt",
    "iso": "en-PT",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Portugal",
    "country": "PT",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltdcc7564bdaa48ad6/649af9ab13bff850842ece66/pt.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-pt.json"
      }
    ]
  },
  {
    "code": "en-ro",
    "iso": "en-RO",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Romania",
    "country": "RO",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt9d934d6d4b227d7c/649af9ab48bdd27f13103fc4/ro.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-ro.json"
      }
    ]
  },
  {
    "code": "en-cs",
    "iso": "en-CS",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Serbia",
    "country": "CS",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt70146176074d25ee/649af9ab7c84d2a62fc29cc3/rs.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-cs.json"
      }
    ]
  },
  {
    "code": "en-sk",
    "iso": "en-SK",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Slovakia",
    "country": "SK",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltc49702c6e841afe0/649af9ab84a4c71221d8d4ce/sk.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-sk.json"
      }
    ]
  },
  {
    "code": "en-si",
    "iso": "en-SI",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Slovenia",
    "country": "SI",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blteabb1eb5e927a6d6/649af9abc18599ec660931eb/si.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-si.json"
      }
    ]
  },
  {
    "code": "en-es",
    "iso": "en-ES",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Spain",
    "country": "ES",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/bltee7aa15af1d8fab3/649af9ac24cb331a157f34dd/es.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-es.json"
      }
    ]
  },
  {
    "code": "en-se",
    "iso": "en-SE",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Sweden",
    "country": "SE",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt60db04aba4f04729/649af9abc411218ceea1945c/se.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-se.json"
      }
    ]
  },
  {
    "code": "en-ch",
    "iso": "en-CH",
    "lang": "en",
    "name": "EU",
    "fullName": "English - Switzerland",
    "country": "CH",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-ch.json"
      }
    ]
  },
  {
    "code": "fr-ch",
    "iso": "fr-CH",
    "lang": "fr",
    "name": "EU",
    "fullName": "French - Switzerland",
    "country": "CH",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt328d4ef26b81ed92/65d38ddae52e7533cf43e109/FR_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/fr-ch.json"
      }
    ]
  },
  {
    "code": "de-ch",
    "iso": "de-CH",
    "lang": "de",
    "name": "EU",
    "fullName": "German - Switzerland",
    "country": "CH",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt3a2591c54929e161/649af9ac05ac38281daeb985/ch.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt8dd41ea0c2b6d099/65d38e4e27ec8d4a1d4a421e/DE_JSON_Update_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/de-ch.json"
      }
    ]
  },
  {
    "code": "en-gb",
    "iso": "en-GB",
    "lang": "en",
    "name": "EU",
    "fullName": "English - United Kingdom",
    "country": "GB",
    "storeHash": "3js3lsrgjq",
    "regionCode": "EU",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blt47f5ca5cca306b19/649af9ace9365a441fc3555a/gb.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-gb.json"
      }
    ]
  },
  {
    "code": "en-us",
    "iso": "en-US",
    "lang": "en",
    "name": "US",
    "fullName": "English - United States",
    "country": "US",
    "storeHash": "b5b4c",
    "regionCode": "US",
    "flagUrl": "https://images.contentstack.io/v3/assets/blt785a775130b0beb1/blte865b2fe7c05cb50/649af9ab96c4d6554ea41cbd/us.svg?branch=dam_v2",
    "dynamicTextUrl": "https://assets.contentstack.io/v3/assets/blt785a775130b0beb1/blt6a0b5ce878f161a0/65d38cc027682ea864e9c2aa/EN_JSON_UPDATE_2_19.json?branch=dam_v2",
    "files": [
      {
        "path": "lang/en-us.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
