// composables/useCloudinary.ts

import { Cloudinary } from '@cloudinary/url-gen';
import { fill, fit, scale, crop } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/quality';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { useConnectionStore } from '~/store/connection';

type Transform = 'fill' | 'fit' | 'extend' | 'scale' | 'crop' | 'rotate' | 'flip' | 'mirror' | 'border' | 'background';

type Gravity = 'center' | 'top' | 'topleft' | 'topright' | 'left' | 'right' | 'bottom' | 'bottomleft' | 'bottomright';

// Initialize Cloudinary

export const useCloudinary = () => {
  // const config = useRuntimeConfig();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'solostove',
    },
    url: {
      secure: true,
      secureDistribution: 'content.solostove.com',
      privateCdn: true,
    },
  });
  const generateImageUrl = (
    publicId: string,
    options?: { width?: number; height?: number; crop?: string; transform?: Transform }
  ) => {
    const image = cld.image(publicId);

    if (options) {
      const resizeAction = transformToResizeMode(options?.transform || 'fill');
      if (options?.width) resizeAction.width(Math.round(options.width));
      if (options?.height) resizeAction.height(Math.round(options.height));
      if (options?.width || options?.height) image.resize(resizeAction);
    }
    image.quality(auto()).format(auto());

    return image.toURL();
  };

  const generateVideoUrl = (
    publicId: string,
    options?: { width?: number; height?: number; thumbnail?: boolean; transform?: Transform }
  ) => {
    const video = cld.video(publicId);

    const connectionStore = useConnectionStore();
    const isFastConnection = connectionStore.fastConnection;
    let isThumbnail = false;
    if (options) {
      const dprScale = isFastConnection ? window.devicePixelRatio : 1;
      // ensure all dimensions are rounded to the nearest whole number
      const resizeAction = transformToResizeMode(options?.transform || 'fill');
      if (options?.width) resizeAction.width(Math.round(options.width));
      if (options?.height) resizeAction.height(Math.round(options.height));
      if (options.thumbnail) isThumbnail = options.thumbnail;
      if (options?.width || options?.height) video.resize(resizeAction).delivery(dpr(dprScale));
    }
    if (!isThumbnail) {
      video.quality(auto()).format('auto:video');
    } else {
      video.quality(auto()).format(auto());
    }
    return video.toURL();
  };

  const transformToResizeMode = (transform: Transform) => {
    switch (transform) {
      case 'fill':
        return fill();
      case 'fit':
        return fit();
      case 'scale':
        return scale();
      case 'crop':
        return crop();
      default:
        return fill(); // Default or fallback to 'fill'
    }
  };

  const bynderToCloudinaryResize = (
    publicId: string,
    width: number,
    height?: number,
    transform: Transform = 'fill',
    _gravity: Gravity = 'center' // deprecated
  ): string => {
    const image = cld.image(publicId);
    // ensure all dimensions are rounded to the nearest whole number
    width = Math.round(width);
    if (height) {
      height = Math.round(height);
    }

    const resizeAction = transformToResizeMode(transform).width(width);
    if (height) {
      resizeAction.height(height);
    }

    image.resize(resizeAction);
    image.quality(auto()).format(auto());

    return image.toURL();
  };

  return {
    generateImageUrl,
    generateVideoUrl,
    bynderToCloudinaryResize,
  };
};
